import { stringify, parse } from 'qs';
import request from '@/utils/request';
import { dealWithFilter, dealWithUrl } from './utils';

export const OSS_IMAGE_PREFIX = '/base_media_url';

// 获取用户信息
export async function fetchCurUser() {
  return request('/api/me');
}

// 用户登录
export async function login(params) {
  return request('/api/oauth/token', {
    method: 'POST',
    data: params,
  });
}

// 退出登录
export async function logout() {
  window.location.replace(`${window.location.origin}/user/login`);
  return request('/api/authorizations', {
    method: 'DELETE',
    keepalive: true,
  });
}

// 获取店铺所有domain/vendor/part/username列表
export async function fetchStore(params) {
  const url = dealWithUrl('/api/stores/lists', params);
  return request(url);
}

// 获取用户tag列表
export async function fetchTag() {
  return request('/api/tag');
}

// 新增店铺标记
export async function addTag(params) {
  return request('/api/tag/store', {
    method: 'POST',
    data: params,
  });
}

// 删除店铺标记
export async function removeTag(params) {
  return request('/api/tag/store', {
    method: 'DELETE',
    data: params,
  });
}

// 获取店铺订单数据列表
export async function fetchOrder(params) {
  let url = '/api/order/total';
  if (params && Object.keys(params).length) {
    const query = dealWithFilter(params);
    url += query;
  } else {
    url += '?page=1';
  }
  return request(url);
}

// 获取店铺ROI和像素分析数据
export async function fetchOverView(params) {
  const url = dealWithUrl('/api/stores/day_list', params);
  return request(url);
}

// 获取所有店铺信息
// export async function fetchStoreInfo(params) {
//   const url = dealWithUrl('/api/stores', params);
//   return request(url);
// }
export async function fetchStoreInfo(data) {
  return request(`/api/stores`, {
    method: 'POST',
    data,
  });
}

export async function exportStoreInfo(data) {
  return request(`/api/stores`, {
    method: 'POST',
    data,
    headers: {
      export: 'xlsx',
    },
  });
}

// 更新店铺信息
export async function updateStoreInfo(params) {
  const { id, headers } = params;
  delete params.headers;
  return request(`/api/store/${id}`, {
    method: 'PUT',
    data: params,
    headers,
  });
}

// 获取所有shopify合作者账号
export async function fetchPartners(params) {
  const url = dealWithUrl('/api/partners', params);
  return request(url);
}

// 创建/更新店铺流量程序 token接口
export async function addAutotoken(params) {
  return request('/api/store/autotoken', {
    method: 'POST',
    data: params,
  });
}

// 创建/更新shopify合作者账号信息
export async function addPartners(params) {
  return request('/api/partners', {
    method: 'POST',
    data: params,
  });
}

// 获取订单/销量/销售额趋势
export async function fetchDashOrderTrend(params) {
  const url = dealWithUrl('/api/dashboard/order_trend', params);
  return request(url);
}

// 总的ROI趋势及分部门ROI趋势
export async function fetchDashROI(params) {
  const url = dealWithUrl('/api/dashboard/roi_trend', params);
  return request(url);
}

// 获取TOP排行数据
export async function fetchDashTopData(params) {
  const url = dealWithUrl('/api/dashboard/top_orders', params);
  return request(url);
}

// 获取广告账户查询列表
export async function fetchAdAccounts(data) {
  return request('/api/fb/accounts/search', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

// 导出广告账户查询列表
export async function exportAdAccounts(data) {
  const { select } = data;
  delete data.select;
  return request('/api/fb/accounts/search', {
    method: 'POST',
    data,
    removeBlankProperty: true,
    headers: {
      export: 'yes',
      select,
    },
  });
}

// 获取广告所有筛选项列表
export async function fetchAdAccountOptions() {
  return request('/api/fb_ad/account_options');
}

// 获取广告所有筛选项列表
export async function fetchAdAccountProviders() {
  return request('/api/fb_ad/get_providers');
}

// 广告账户ID和名称关键词列表搜索
export async function fetchActidnameList(params) {
  const url = dealWithUrl('/api/fb_ad/actidname_list', params);
  return request(url);
}

// 获取广告账号每天消耗查询
export async function fetchAdSpendDayList(params) {
  const url = dealWithUrl('/api/adspend/day_list', params);
  return request(url);
}

// 获取所有广告账号店铺关联数据
export async function fetchActDomain(params) {
  const url = dealWithUrl('/api/act_domain', params);
  return request(url);
}

// 创建新的账号店铺关联关系
export async function addActDomain(params) {
  return request('/api/act_domain', {
    method: 'POST',
    data: params,
  });
}
// 更新已有的广告账号店铺关联关系
export async function updateActDomain(params) {
  const { id } = params;
  return request(`/api/act_domain/${id}`, {
    method: 'PUT',
    data: params,
  });
}

// 删除已有的广告账号店铺关联关系
export async function deleteActDomain(params) {
  const { id } = params;
  return request(`/api/act_domain/${id}`, {
    method: 'DELETE',
  });
}

// 获取店铺详情页数据
export async function fetchDetailById(payload) {
  const { id, params } = payload;
  const url = dealWithUrl(`/api/store/${id}`, params);
  return request(url);
}

// 获取用户子账号列表
export async function fetchUsersStaff(data) {
  return request('/api/users', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

// 导出用户子账号列表
export async function exportUsersStaff(data) {
  return request('/api/users', {
    method: 'POST',
    data,
    removeBlankProperty: true,
    headers: {
      export: 'xlsx',
    },
  });
}

// 获取非sku列表被的订单sku明细
export async function fetchDiffSku(params) {
  const url = dealWithUrl('/api/diffsku', params);
  return request(url);
}

// 获取子用户接口搜索项接口
export async function fetchUsersStaffOptions(params) {
  return request('/api/users/staff_options', {
    method: 'GET',
    params,
  });
}

// 获取用户角色列表
export async function fetchRolesOptions() {
  return request('/api/roles/options');
}

// 新建用户子账号
export async function addUsersStaff(params) {
  return request('/api/users/staff', {
    method: 'POST',
    data: params,
  });
}

// 删除用户部门
export async function deleteUsersPart(params) {
  return request('/api/users/del_part', {
    method: 'POST',
    data: params,
  });
}

// 新增/更新用户部门
export async function addUsersPart(params) {
  return request('/api/users/add_part', {
    method: 'POST',
    data: params,
  });
}

// 读取所有部门
export async function fetchPart(params) {
  return request('/api/part', {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}
export async function fetchReviewPeopleList(data) {
  return request('/api/after-sale-reviews/assigners', {
    method: 'GET',
    data,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 新增部门
export async function addPart(params) {
  return request('/api/part', {
    method: 'POST',
    data: params,
  });
}

// 新增店铺
export async function addStore(params) {
  return request('/api/store', {
    method: 'POST',
    data: params,
  });
}

// 删除用户管理店铺
export async function deleteUsersStore(params) {
  return request('/api/users/del_store', {
    method: 'POST',
    data: params,
  });
}

// 新增用户管理店铺
export async function addUsersStore(params) {
  return request('/api/users/add_store', {
    method: 'POST',
    data: params,
  });
}

// 获取用户所有店铺的domain/vendor/part/username列表
export async function fetchStoresLists() {
  return request('/api/stores/lists');
}

// 更新广告账号
export async function updateAdAccount(params) {
  const { id } = params;
  return request(`/api/adaccount/${id}`, {
    method: 'PUT',
    data: params,
  });
}

// 新增广告账号
export async function addAdAccount(params) {
  return request('/api/adaccount', {
    method: 'POST',
    data: params,
  });
}

// 店铺搜索中关键词搜索列表
export async function fetchThirdLists(params) {
  const url = dealWithUrl('/api/stores/third_domains', params);
  return request(url);
}

// 获取指定分类ROI和像素分析数据
export async function fetchGroupRoi(params) {
  const url = dealWithUrl('/api/stores/day_total', params);
  return request(url);
}

// 获取店铺业务数据监控信息
export async function fetchMonitorStores() {
  return request('/api/monitor/stores');
}

// 删除指定ID店铺
export async function removeStore(params) {
  const { id } = params;
  return request(`/api/store/${id}`, {
    method: 'DELETE',
  });
}

// 增加店铺与人员对应关系的开始时间
export async function updateUserLog(params) {
  const { id } = params;
  return request(`/api/store_username_log/${id}`, {
    method: 'PUT',
    data: params,
  });
}
// 新增广告账号的主页ID
export async function addAdPage(params) {
  return request('/api/ad_page', {
    method: 'POST',
    data: params,
  });
}

// 删除广告账号的主页ID
export async function deleteAdPage(params) {
  const { id } = params;
  return request(`/api/ad_page/${id}`, {
    method: 'DELETE',
  });
}

// 获取广告系列数据
export async function fetchCampaignSpendDay(params) {
  const url = dealWithUrl('/api/fb_ad/campaignSpendDay', params);
  return request(url);
}

// 读取pp邮箱列表
// export async function fetchPP() {
//   return request('/api/pp_options');
// }
export async function fetchPP(params) {
  const url = dealWithUrl('/api/pp_options', params);
  return request(url);
}

// 增加系统PP账号(PP邮箱)
export async function addPP(params) {
  return request('/api/pp_info_email', {
    method: 'POST',
    data: params,
  });
}

// 获取广告素材数据
export async function fetchAdMaterial(data) {
  // const url = dealWithUrl('/api/fb_ad/postInfo', params);
  // return request(url);

  return request('/api/fb-ad/post-info', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

// 素材审核 审核日志
export function getAdMaterialLog(params) {
  return request('/api/fb-ad/post-info/log', {
    params,
  });
}

// 更新广告素材状态
export async function updateAdPostInfo(params) {
  return request('/api/fb_ad/postInfo/update', {
    method: 'POST',
    data: params,
  });
}

// 即刻更新店铺的shopify信息
export async function fetchShopInfo(id) {
  return request(`/api/stores/get_shopinfo?id=${id}`);
}

// 获取PP收款
export async function fetchPPAmount(params) {
  const url = dealWithUrl('/api/dashboard/pp_amount', params);
  return request(url);
}

// 获取人员列表
export async function fetchPeople(params) {
  const url = dealWithUrl('/api/peoples', params);
  return request(url);
}
// 获取申请用户列表
export async function fetchApplyUser(params) {
  const url = dealWithUrl('/api/user/apply_options', params);
  return request(url);
}

// 创建人员
export async function addPeople(params) {
  return request('/api/peoples', {
    method: 'POST',
    data: params,
  });
}

// 删除人员
export async function removePeople(params) {
  const { id } = params;
  return request(`/api/peoples/${id}`, {
    method: 'DELETE',
  });
}

// 修改人员
export async function updatePeople(params) {
  const { id } = params;
  return request(`/api/peoples/${id}`, {
    method: 'PUT',
    data: params,
  });
}

// 获取FB账户花费详情
export async function fetchAdSpendDayTotal(data) {
  return request('/api/fb_ad_spend', {
    method: 'POST',
    data,
  });
}

// 广告账号花费导出Excel
export async function exportSpend(params) {
  const url = dealWithUrl('/api/adspend/total_download', params);
  return request(url);
}

// 获取订单详情
export async function fetchOrders(params) {
  const url = dealWithUrl('/api/orders', params);
  return request(url);
}

// 修改密码
export async function updatePwd(params) {
  return request('/api/me/password', {
    method: 'POST',
    data: params,
  });
}

// 创建异常信息
export async function addErrorMsg(params) {
  return request('/api/error_msg', {
    method: 'POST',
    data: params,
  });
}

// 删除异常信息
export async function removeErrorMsg(params) {
  const { id } = params;
  return request(`/api/error_msg/${id}`, {
    method: 'DELETE',
  });
}

// 修改异常信息
export async function updateErrorMsg(params) {
  const { id } = params;
  const temp = params;
  delete temp.id;
  return request(`/api/error_msg/${id}`, {
    method: 'PUT',
    data: temp,
  });
}

// 获取所有异常信息
export async function fetchErrorMsg(data) {
  return request('/api/error_msg/list', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

export async function fetchSkuManage(params) {
  return request('/api/search_sku', {
    method: 'POST',
    data: params,
  });
}

// 创建部门配置
export async function addPartConfig(params) {
  return request('/api/config', {
    method: 'POST',
    data: params,
  });
}

// 修改部门配置
export async function updatePartConfig(params) {
  const { id } = params;
  return request(`/api/config/${id}`, {
    method: 'PUT',
    data: params,
  });
}
// 批量修改部门配置
export async function batchUpdatePartConfig(params) {
  return request('/api/configs/batch_update', {
    method: 'PUT',
    data: params,
  });
}

// 获取所有部门配置
export async function fetchPartConfig(params) {
  const url = dealWithUrl('/api/config', params);
  return request(url);
}

// 获取授权地址
export async function fetchInstall(params) {
  const url = dealWithUrl('/api/shopify/install', params);
  return request(url);
}

// 获取店铺授权token
export async function fetchToken(params) {
  const url = dealWithUrl('/api/shopify/callback', params);
  return request(url);
}

// 获取所有在库的商品
export async function fetchProducts(params) {
  return request(`/api/products`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}

// 创建商品
export async function addProducts(params) {
  return request('/api/products', {
    method: 'POST',
    data: params,
  });
}

// 修改商品
export async function updateProducts(params) {
  const { id } = params;
  return request(`/api/products/${id}`, {
    method: 'PUT',
    data: params,
  });
}

// 删除商品
export async function deleteProduct(id) {
  return request(`/api/products/${id}`, {
    method: 'DELETE',
  });
}

// 通过url导入商品
export async function addProductByUrl(data) {
  //
  return request('/api/products/import/logs', {
    method: 'POST',
    data,
  });
}
export async function stockImport(data) {
  return request('/api/sku/stock/import', {
    method: 'POST',
    data,
  });
}
// 导出商品
export async function exportProduct(params) {
  return request('/api/products/export/logs', {
    method: 'POST',
    data: params,
  });
}

// 获取特定商品的所有变体
export async function fetchVariants(params) {
  const { id } = params;
  const url = dealWithUrl(`/api/products/${id}/variants`, params);
  return request(url);
}

// 创建变体
export async function addVariants(params) {
  const { productId } = params;
  return request(`/api/products/${productId}/variants`, {
    method: 'POST',
    data: params,
  });
}

// 修改变体
export async function updateVariants(params) {
  const { id, productId } = params;
  return request(`/api/products/${productId}/variants/${id}`, {
    method: 'PUT',
    data: params,
  });
}

// 删除变体
export async function deleteVariants(params) {
  const { id, productId, sync } = params;
  return request(`/api/products/${productId}/variants/${id}${sync ? `?sync=${sync}` : ''}`, {
    method: 'DELETE',
  });
}

// 获取特定商品的图片资源列表
export async function fetchImages(params) {
  const { id } = params;
  const url = dealWithUrl(`/api/products/${id}/images`, params);
  return request(url);
}

// 创建图片资源
export async function addImages(params) {
  const { productId } = params;
  return request(`/api/products/${productId}/images`, {
    method: 'POST',
    data: params,
  });
}

// 修改图片资源
export async function updateImages(params) {
  const { id, productId } = params;
  return request(`/api/products/${productId}/images/${id}`, {
    method: 'PUT',
    data: params,
  });
}

// 删除图片资源
export async function deleteImages(params) {
  const { id, productId } = params;
  return request(`/api/products/${productId}/images/${id}`, {
    method: 'DELETE',
  });
}

// 团队ROI导出Excel
export async function exportGroupRoi(params) {
  const url = dealWithUrl('/api/stores/day_total/export', params);
  return request(url);
}

// 获取特定商店的所有自定义集合
export async function fetchCollection(domain) {
  return request(`/api/stores/${domain}/collections`);
}

// 导出日志列表
export async function fetchLogs(params) {
  const url = dealWithUrl('/api/products/export/logs', params);
  return request(url);
}

// 查看指定导出日志ID的具体信息
export async function fetchLogsWithID(id) {
  return request(`/api/products/export/logs/${id}`);
}

// 查询指定商品的信息
export async function fetchProductsWithID(id) {
  return request(`/api/products/${id}`);
}

// 同步商品信息
export async function fetchSyncProduct(params) {
  const { id, fetch } = params;
  return request(`/api/products/${id}/sync${fetch ? `?fetch=${fetch}` : ''}`);
}

// 从A商店把变种导入B商店
export async function exportByComplex(params) {
  return request('/api/complex/export', {
    method: 'POST',
    data: params,
  });
}

// 查询指定用户的商店
export async function fetchUserStores(id) {
  return request(`/api/users/stores?user_id=${id}`);
}

// 更新用户所在部门/角色/权限
export async function updateUserInfo(params) {
  return request('/api/users/update_info', {
    method: 'POST',
    data: params,
  });
}

// 删除多个商品
export async function deleteMultiProducts(params) {
  return request('/api/products/extermination', {
    method: 'DELETE',
    data: params,
  });
}

// 获取指定时间区间内店铺订单销量数据
export async function fetchSpecifiedOrders(data) {
  // const url = dealWithUrl('/api/stores/orders', params);
  // return request(url);
  return request('/api/store-orders', {
    method: 'POST',
    data,
  });
}

// 获取指定时间区间内店铺订单销量数据汇总
export async function fetchSpecifiedOrdersSumarry(data) {
  return request('/api/store-orders', {
    method: 'POST',
    data: {
      ...data,
      isTotal: 1,
    },
  });
}

// 获取权限列表
export async function fetchPermissions(type) {
  return request(`/api/permission/permission_options${type ? `?type=${type}` : ''}`);
}

// 获取导入日志的列表
export async function fetchQueryLogs(params) {
  const url = dealWithUrl('/api/products/import/logs', params);
  return request(url);
}

// 获取计算汇率
export async function fetchRate(params) {
  const url = dealWithUrl('/api/rate', params);
  return request(url);
}

// 获取转换汇率列表
export async function fetchRateOptions() {
  return request('/api/rate_options');
}

// 获取特定商品的属性
export async function fetchProductOptions(id) {
  return request(`/api/products/${id}/options`);
}

// 创建商品属性
export async function addProductOptions(params) {
  const { id } = params;
  return request(`/api/products/${id}/options`, {
    method: 'POST',
    data: params,
  });
}

// 修改商品属性
export async function updateProductOptions(params) {
  const { id } = params;
  return request(`/api/products/${id}/options`, {
    method: 'PUT',
    data: params,
  });
}

// 删除商品属性
export async function deleteProductOptions(params) {
  const { id } = params;
  return request(`/api/products/${id}/options`, {
    method: 'DELETE',
  });
}

// 查看导入日志ID的具体信息
export async function fetchImportLogsWithID(id) {
  return request(`/api/products/import/logs/${id}`);
}

// 订单汇总导出Excel
export async function exportOrderTotal(data) {
  const { select } = data;
  delete data.select;
  // const url = dealWithUrl('/api/stores/download_orders', params);
  // return request(url);
  return request('/api/store-orders', {
    method: 'POST',
    data,
    headers: {
      export: 1,
      select,
    },
  });
}

// 异常信息备注
export async function remarkErrMsg(params) {
  const { id } = params;
  return request(`/api/error_msg/${id}/remark`, {
    method: 'PUT',
    data: params,
  });
}

// 异常信息回复
export async function replayErrMsg(params) {
  return request('/api/error_msg/deal', {
    method: 'POST',
    data: params,
  });
}

// 获取处理人列表
export async function fetchDealPeople() {
  return request('/api/error_msg/deal_user', {
    method: 'POST',
  });
}

export async function dealErrorMsg(params) {
  return request('/api/error_msg/deal', {
    method: 'POST',
    data: params,
  });
}

// 获取订单物流管理
export async function fetchTracking(params) {
  const url = dealWithUrl('/api/fulfillments/logs', params);
  return request(url);
}

// 订单物流管理提交excel
export async function uploadTrackingExcel(params) {
  return request('/api/fulfillments/logs', {
    method: 'POST',
    data: params,
  });
}

// 获取具体订单物流管理
export async function fetchTrackingLogsWithID(id) {
  return request(`/api/fulfillments/logs/${id}`);
}

// 获取具体订单物流管理
export async function fetchTrackingLogsWithIdDetail(params) {
  const { id } = params;
  delete params.id; // eslint-disable-line
  const url = dealWithUrl(`/api/fulfillments/logs/${id}/details`, params);
  return request(url);
}

// 下载物流管理失败文件
export async function fetchFailedExcel(id) {
  return request(`/api/fulfillments/logs/${id}/export`);
}

// 下载物流管理模板
export async function fetchTemplateExcel() {
  return request('/api/fulfillments/import-template');
}

// 获取新增子用户列表
export async function fetchNoAccountUsers() {
  return request('/api/no_account_people');
}

// SKU核算
export async function fetchSkuCalc(params) {
  return request('/api/sale_calculate', {
    method: 'POST',
    data: params,
  });
}

// 订单的SKU数据
export async function fetchOrderSkuCalc(params) {
  return request('/api/profit/sku-calc', {
    params,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 订单的SKU数据同步导出
export async function exportOrderSkuCalc(params) {
  return request('/api/profit/sku-calc', {
    params,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
    headers: {
      export: 'xlsx',
    },
  });
}

// 订单的SKU数据异步导出
export async function createOrderSkuCalcTask(params) {
  return request('/api/profit/sku-calc/async-export', {
    params,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
    removeBlankProperty: true,
  });
}

// SKU指定属性配置列表
export async function fetchSkuAttributeConfig(params) {
  return request('/api/profit/sku-attribute-config', {
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 导出SKU指定属性配置列表
export async function exportSkuAttributeConfig(params) {
  return request('/api/profit/sku-attribute-config', {
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
    headers: {
      export: 'yes',
    },
  });
}

// 移除SKU指定属性配置
export function deleteSKUAttributeConfig(ids) {
  return request(`/api/profit/sku-attribute-config/${ids}`, {
    method: 'DELETE',
  });
}

// 获取SKU指定属性配置导入模版
export function fetchSkuAttributeConfigTemplate() {
  return request(`/api/profit/sku-attribute-config/import-template`, {
    method: 'GET',
  });
}

//导入SKU指定属性配置
export function importSkuAttributeConfig(data) {
  return request(`/api/profit/sku-attribute-config/import`, {
    method: 'POST',
    data,
  });
}

// 订单的SKU数据异步导出
export async function addOrderSkuCalcTask(params) {
  return request('/api/sale_calculate/order-sku-calc/async-export', {
    method: 'POST',
    data: params,
  });
}

// 订单的SKU数据订单详情异步导出
export async function addOrderSkuCalcDetailTask(data) {
  return request('/api/profit/sku-calc/order-freight-export', {
    method: 'POST',
    data: parse(data, { arrayFormat: 'brackets' }),
    removeBlankProperty: true,
  });
}

// 批量修改变体
export async function batchUpdateVariants(params) {
  const { productId, data } = params;
  return request(`/api/products/${productId}/variants`, {
    method: 'PUT',
    data,
  });
}

// 更新sku成本和重量
export async function updateCostHeight(params) {
  return request('/api/sale_calculate_update', {
    method: 'POST',
    data: params,
  });
}

// 核算导出sku
export async function exportSkuCalc(params) {
  return request('/api/sale_calculate/export', {
    method: 'POST',
    data: params,
  });
}

// 核算导出国家
export async function exportCountryExcel(data) {
  return request('/api/country/sale_calculate/export', {
    method: 'post',
    data,
  });
}

// 获取订单详情
export async function fetchOrderDetail(params) {
  return request('/api/orders/details', {
    params,
  });
}

// 获取广告户充值列表
export async function fetchAdCharge(params) {
  const url = dealWithUrl('/api/adaccount/charge', params);
  return request(url);
}

// 获取所有消息
export async function fetchMessage(params) {
  const url = dealWithUrl('/api/messages', params);
  return request(url);
}

// 获取消息详情
export async function fetchMessageDetails(id) {
  return request(`/api/messages/${id}`);
}

// 广告户充值/清零
export async function accountAction(params) {
  return request('/api/adaccount/charge', {
    method: 'POST',
    data: params,
  });
}

// 创建消息
export async function addMessage(params) {
  return request('/api/messages', {
    method: 'POST',
    data: params,
  });
}

// 广告户保存自动充值参数
export async function UpdateAutoChargeConfig(params) {
  return request('/api/fb/accounts/auto-recharge-configs', {
    method: 'POST',
    data: params,
  });
}

// SKU核算导入Excel
export async function importExcel(params) {
  return request('/api/sale_calculate_update/import', {
    method: 'POST',
    data: params,
  });
}

// 申请广告账户
export async function applyAdAccount(params) {
  return request('/api/adaccount/apply', {
    method: 'POST',
    data: params,
  });
}

// 更新消息
export async function updateMessage(params) {
  const { id } = params;
  return request(`/api/messages/${id}`, {
    method: 'PUT',
    data: params,
  });
}

// 下载模板
export async function downExcelModel() {
  return request(`/api/sale_template`);
}

// 广告户预分配
export async function assignAdAccount(params) {
  return request('/api/adaccount/assign', {
    method: 'POST',
    data: params,
  });
}

// 删除消息
export async function removeMessage(params) {
  const { id } = params;
  return request(`/api/messages/${id}`, {
    method: 'DELETE',
    data: params,
  });
}

// 毛利核算部门数据
export async function getProfitWithPart(params) {
  return request('/api/statistical', {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}

// 广告户预分配确认
export async function assignCheckAdAccount(params) {
  return request('/api/adaccount/assign_check', {
    method: 'POST',
    data: params,
  });
}

// 发送消息
export async function addNotification(params) {
  return request('/api/notifications', {
    method: 'POST',
    data: params,
  });
}

// 毛利核算人员数据
export async function getProfitWithPeople(params) {
  const url = dealWithUrl('/api/statistical', params);
  return request(url);
}

// 获取广告账户申请列表
export async function fetchAdApply(params) {
  const url = dealWithUrl('/api/adaccount/apply', params);
  return request(url);
}

// 获取所有未读消息
export async function fetchNotifications() {
  return request('/api/notifications');
}

// 设置消息为已读
export async function updateNotification() {
  return request(`/api/notifications`, {
    method: 'PUT',
  });
}

// 获取所有审核消息
export async function fetchReviewMessages(params) {
  const url = dealWithUrl('/api/messages_review', params);
  return request(url);
}

// 修改广告账户申请
export async function updateAdApply(params) {
  return request('/api/adaccount/modify_apply', {
    method: 'POST',
    data: params,
  });
}

// 获取所有历史消息
export async function fetchAllNotifications(params) {
  const url = dealWithUrl('/api/notifications', params);
  return request(url);
}

// 收到消息报表
export async function fetchNotificationsStatistics(params) {
  return request('/api/notifications/statistics', {
    method: 'GET',
    params,
    removeBlankProperty: true,
    // responseType: 'blob',
  });
}

// 导出消息中心报表
export async function exportNotificationsStatistics(params) {
  const { select } = params;
  delete params.select;
  return request('/api/notifications/statistics', {
    method: 'GET',
    params,
    removeBlankProperty: true,
    headers: {
      export: 'xlsx',
      select,
    },
  });
}

// 获取指定运营->指定人员列表
export async function fetchPersonnels() {
  return request('/api/personnels');
}

// 账号资源组 - 主页申请
export async function fetchPageIdList(params) {
  const url = dealWithUrl('/api/asset/page_apply', params);
  return request(url);
}

// 资产关系
export async function fetchAssets(data) {
  // const url = dealWithUrl('/api/asset/asset_list', params);
  // return request(url);
  return request('/api/asset/asset_list', {
    method: 'POST',
    data,
  });
}

// 账号资源组 - 账户申请
export async function fetchAccountAssets(params) {
  const url = dealWithUrl('/api/asset/act_apply', params);
  return request(url);
}

// 获取主题
export async function fetchTheme() {
  return request('/api/theme');
}

// sku核算运营人员请求未填写的重量和成本
export async function getPeopleUnUsual(params) {
  const url = dealWithUrl('/api/sale_calculate/sku_unusual', params);
  return request(url);
}

// 资产关系 - 账户申请
export async function actApply(params) {
  return request('/api/asset/act_apply', {
    method: 'POST',
    data: params,
  });
}

// 资产关系 - 申请主页
export async function actPage(params) {
  return request('/api/asset/page_apply', {
    method: 'POST',
    data: params,
  });
}

// 获取现有第三方域名
export async function fetchThirdDomain(params) {
  const url = dealWithUrl('/api/asset/third_domain', params);
  return request(url);
}

// 获取现有店铺名称
export async function fetchStoreDomain(id) {
  return request(`/api/asset/store_domain${id ? `?people_id=${id}` : ''}`);
}

// 商品管理 - 获取labels
export async function fetchLabels() {
  return request('/api/labels');
}

// 商品管理 - 批量共享商品
export async function shareMultiProducts(params) {
  return request('/api/products/share', {
    method: 'PUT',
    data: params,
  });
}

// 商品管理 - 批量修改商品
export async function batchUpdateProducts(params) {
  return request('/api/products', {
    method: 'PUT',
    data: params,
  });
}

// articleType 类型
export const articleType = {
  FAQ: 'FAQ', // 帮助手册
  CHANGELOG: 'CHANGELOG', // 更新日志
};
// 获取文章的子类型
export async function fetchArticleSub(type) {
  return request(`/api/article/sub_type?type=${type}`);
}

// 获取文章
export async function fetchArticleList({ id, type }) {
  return request(`/api/article?id=${id}&type=${type}`);
}

// 账户资源组 - 创建主页
export async function createPage(params) {
  return request('/api/asset/create_page', {
    method: 'POST',
    data: params,
  });
}

// 账户资源组 - 分配账户
export async function assignAccount(params) {
  return request('/api/asset/assign_account', {
    method: 'POST',
    data: params,
  });
}

// 账户资源组 - 分配备用VPS
export async function assignVpsBk(params) {
  return request('/api/asset/assign_bk_vps', {
    method: 'POST',
    data: params,
  });
}

// 账户资源组 - 分配店铺
export async function assignStore(params) {
  return request('/api/asset/assign_store', {
    method: 'POST',
    data: params,
  });
}

// 账户资源组 - 获取vps列表
export async function fetchVpsList(params) {
  return request('/api/asset/vps', { params });
}

// 账户资源组 - 分配vps
export async function assignVps(params) {
  return request('/api/asset/assign_vps', {
    method: 'POST',
    data: params,
  });
}

// 物流查询列表
export async function fetchQueryList(params) {
  return request('/api/fulfillments/trajectory', {
    method: 'POST',
    data: params,
  });
}

// 获取物流轨迹分类导入模版
export async function fetchTrajectoryCategoriesTemplate() {
  return request(`/api/fulfillment/categories/rules`, {
    params: {
      'filter[ids]': 0,
    },
  });
}

// 导入物流轨迹分类
export async function importTrajectoryCategories(data) {
  return request(`/api/fulfillment/categories/rules/import`, {
    data,
    method: 'POST',
  });
}

// 导出物流轨迹分类
export async function exportTrajectoryCategories() {
  return request(`/api/fulfillment/categories/rules`, {});
}

// 下载物流查询模板
export async function fetchQueryTemplateExcel() {
  return request('/api/fulfillments/template');
}

// 获取违规素材案例
export async function fetchViolation(params) {
  const url = dealWithUrl('/api/fb_ad/postInfo_violation', params);
  return request(url);
}

// 物流查询 - 统计数据
export async function fetchQueryLog(params) {
  return request('/api/fulfillments/logistics_status', {
    method: 'POST',
    data: params,
  });
}

// 资产管理 - 创建像素
export async function applyAssetPixel(params) {
  return request('/api/asset/pixel_apply', {
    method: 'POST',
    data: params,
  });
}

// 物流查询 - 导出物流信息
export async function exportQueryList(params) {
  return request('/api/fulfillments/logistics_export', {
    method: 'POST',
    data: params,
  });
}

// 获取像素申请列表
export async function fetchPixelApply(params) {
  const url = dealWithUrl('/api/asset/pixel_apply', params);
  return request(url);
}

// 物流查询 - 获取导出任务列表
export async function fetchQueryExportTask(params) {
  const url = dealWithUrl('/api/fulfillments/export_task', params);
  return request(url);
}

// 账户资源组 - 创建像素
export async function assignAssetPixel(params) {
  return request('/api/asset/assign_pixel', {
    method: 'POST',
    data: params,
  });
}

// 物流查询 - 删除物流信息导出任务
export async function deleteQueryExportTask(params) {
  return request('/api/fulfillments/export_task', {
    method: 'DELETE',
    data: params,
  });
}

// 物流信息导出任务下载链接
export async function fetchQueryExportTaskUrl(id) {
  return request(`/api/fulfillments/expor_uri?id=${id}`);
}

// 账号申诉页面
export async function fetchAdAppeal(data) {
  return request('/api/asset/ad_appeal_list', {
    method: 'POST',
    data,
  });
}
// 账号申诉页面 => 被封原因
export function fetchAdAppealReason() {
  return request('/api/asset/ad_appeal/reasons');
}

// 更新账户申诉
export async function updateAdAppeal(params) {
  return request('/api/asset/account_appeal', {
    method: 'PUT',
    data: params,
  });
}

// 创建账户申诉
export async function createAdAppeal(params) {
  return request('/api/asset/account_appeal', {
    method: 'POST',
    data: params,
  });
}

// 生成微信二维码
export async function createQRCode() {
  return request(`/api/qr_code`);
}

// 解除小程序绑定
export async function removeBindingWithMiniApp() {
  return request('/api/relieve/mini_bind', {
    method: 'PUT',
  });
}

// 解除公众号绑定
export async function removeBindingWithOffice() {
  return request('/api/relieve/office_bind', {
    method: 'PUT',
  });
}

// 生成公众号二维码
export async function createOACode() {
  return request('/api/off_qr_code');
}
// 店铺管理时区变更信息
export async function fetchTimeZoneChangesMsg(params) {
  const { shop_id: shopId } = params;
  return request(`/api/stores/${shopId}/timezone_history`);
}

//  店铺管理人员变更信息
export async function fetchPeopleChangesMsg(params) {
  const { id } = params;
  return request(`/api/stores/${id}/people_history`);
}

// 解析临时token
export async function parseToken(token) {
  return request(`/api/parse_token?access_token=${token}`);
}

// 获取账户申诉操作人列表
export async function fetchAppealOperator() {
  return request('/api/asset/account_appeal_operator');
}

// 取消主页申请
export async function abolishPageApply(data) {
  return request('/api/asset/abolish_page_apply', {
    method: 'POST',
    data,
  });
}

// 分配新Bm给运营
export async function assignBm(params) {
  return request('/api/user/assign_bm', {
    method: 'POST',
    data: params,
  });
}

// 旧更换BM列表
export async function fetchOldBM(params) {
  return request('/api/asset/old_bm_change_email', {
    method: 'get',
    params,
  });
}

// 创建更换个人号
export async function changeOldBMEmail(data) {
  return request('/api/asset/old_bm_change_email', {
    method: 'post',
    data,
  });
}

// 获取店铺变更下时区变更列表
export async function fetchTimeZoneHistory(params) {
  const url = dealWithUrl('/api/stores/timezone_history', params);
  return request(url);
}

// 异常提交上传图片
export async function uploadErrImage(params) {
  return request('/api/upload_image', {
    method: 'POST',
    data: params,
  });
}

// 工单提交回复成功后获取回复列表
export async function fetchReplayList(id) {
  return request(`/api/error_msg_logs/${id}`);
}

// 获取权限类型列表
export async function fetchPermissionTypes() {
  return request('/api/permission/type_options');
}

// 获取用户管理的人员
export async function fetchUserPeople(params) {
  const url = dealWithUrl('/api/users/user_peoples', params);
  return request(url, {
    removeBlankProperty: true,
  });
}

// 新增用户人员管理关联
export async function addUserPeople(params) {
  return request('/api/users/add_user_people', {
    method: 'POST',
    data: params,
  });
}

// 删除用户人员管理关联
export async function deleteUserPeople(params) {
  return request('/api/users/delete_user_people', {
    method: 'POST',
    data: params,
  });
}

// 批量删除用户人员管理关联
export async function batchDeleteUserPeople(params) {
  return request('/api/users/batch-delete-user-people', {
    method: 'POST',
    data: params,
  });
}

// 取消像素申请
export async function abolishPixelApply(params) {
  return request('/api/asset/abolish_pixel_apply', {
    method: 'POST',
    data: params,
  });
}

// 获取审核日志
export async function fetchReviewLog(params) {
  return request('/api/fb_ad/post_info_review_logs', {
    method: 'get',
    params,
  });
}

// 更换BM
export async function changeBm(params) {
  return request('/api/asset/change_bm', {
    method: 'POST',
    data: params,
  });
}

// 更换个人账号
export async function changePersonAccount(data) {
  return request('/api/asset/change_email', {
    method: 'POST',
    data,
  });
}

// 删除账户申请
export async function deleteAccountApply(params) {
  return request('/api/asset/delete_account_apply', {
    method: 'DELETE',
    data: params,
  });
}

// 资产转移
export async function transAsset(data) {
  return request('/api/asset/trans', {
    method: 'POST',
    data,
  });
}

// 新获取人员列表
export async function getPeopleList() {
  return request('/api/part_peoples');
}

// 新增/更换邮箱列表
export async function fetchEmailApplyList(params) {
  return request('/api/asset/change_email_apply', {
    method: 'get',
    params,
  });
}

// 审核中心 =>邮箱申请 => 导出
export async function exportEmailApplyList(params) {
  return request('/api/asset/change_email_apply', {
    method: 'get',
    params,
    headers: {
      export: 'xlsx',
      select: params.select,
    },
    removeBlankProperty: true,
  });
}

// 获取店铺分析/订单分析
export async function fetchAnalysisData(params) {
  const url = dealWithUrl('/api/platform/stores', params);
  return request(url);
}

// 获取账户归属人员变更记录
export async function fetchAdPeopleLog(id) {
  return request(`/api/adaccount_people/log?ad_account_id=${id}`);
}

// 更新账户归属人员记录
export async function updateAdPeopleLog(params) {
  return request('/api/adaccount_people', {
    method: 'PUT',
    data: params,
  });
}

// 重新获取毛利数据
export async function refreshProfitData(params) {
  return request('/api/statistical/afresh', {
    method: 'POST',
    data: params,
  });
}

// 更新毛利核算营业额和订单数
export async function updateProfitData(params) {
  return request('/api/statistical/update_profit', {
    method: 'POST',
    data: params,
  });
}

// 获取关闭广告策略列表
export async function fetchAdPolicies() {
  return request('/api/fb_ad/close_policy');
}

// 更新账户关闭广告策略
export async function updateAdPolicy(params) {
  return request('/api/fb_ad/close_policy', {
    method: 'PUT',
    data: params,
  });
}

// 关闭广告账户广告
export async function closeAccountAd(params) {
  return request('/api/fb_ad/close_account_ad', {
    method: 'POST',
    data: params,
  });
}

// 获取账户关闭广告信息
export async function fetchClosedAd(params) {
  const url = dealWithUrl('/api/fb_ad/account_info', params);
  return request(url);
}

//  获取关闭广告日志
export async function fetchAdPolicyLog(params) {
  const url = dealWithUrl('/api/fb_ad/close_ad_log', params);
  return request(url);
}

//  获取资产审批报表
export async function fetchApproveReport(params) {
  const url = dealWithUrl('/api/approve/asset', params);
  return request(url);
}

//  获取账户申诉报表
export async function fetchAppealReport(params) {
  const url = dealWithUrl('/api/approve/account_appeal', params);
  return request(url);
}

// 获取国家
export async function fetchCountries() {
  return request('/api/countries');
}

// 获取中控国家
export async function fetchBiCountries() {
  return request('/api/bi-countries', {
    removeBlankProperty: true,
  });
}

// sku核算（国家）
export async function fetchSkuCalcCountry(data) {
  return request('/api/country/sale_calculate', {
    method: 'post',
    data,
  });
}

// 部门或人员的导入导出,各状态各平台汇总数据
export async function fetchStatistics(params) {
  return request('/api/product/operate/statistics', {
    method: 'get',
    params,
  });
}

// 获取PP收款情况
export async function fetchPPReceive() {
  return request('/api/papal/receive_payment');
}

// 获取账户余额
export async function fetchPPBalance(params) {
  return request('/api/papal/account_balance', {
    method: 'GET',
    params,
  });
}

// 资产授权邮箱重试
export async function assetEmailMandate(params) {
  return request('/api/asset/email_mandate', {
    method: 'POST',
    data: params,
  });
}

// 商品导入 - 更换图片
export async function changeImages(id) {
  return request(`/api/images/${id}/change`);
}

// 获取Bm下邮箱可查看资产
export async function fetchEmailAsset(params) {
  const url = dealWithUrl('/api/asset/bm_email_asset', params);
  return request(url);
}

// 获取更换BM列表
export async function fetchBmAsset(params) {
  const url = dealWithUrl('/api/asset/bm_change', params);
  return request(url);
}

// 获取BM资产差异
export async function fetchBmAssetDiff(id) {
  return request(`/api/asset/bm_asset_diff?order_id=${id}`);
}

// 商品管理 - 整店导入
export async function fetchAllProducts(params) {
  const url = dealWithUrl('/api/products/import/get-all-products', params);
  return request(url);
}

// 商品管理 - 整店导入
export async function importAllProducts(params) {
  return request('/api/products/import/get-all-products', {
    method: 'POST',
    data: params,
  });
}

// BM统计 - 更换BM统计
export async function fetchBmChange(params) {
  const url = dealWithUrl('/api/approve/bm_change', params);
  return request(url);
}

// BM统计 - 获取邮箱申请报表
export async function fetchBmEmailApply(params) {
  const url = dealWithUrl('/api/approve/bm_email_apply', params);
  return request(url);
}

// 商品管理 - 迁移日志列表
export async function fetchMigrateLogs(params) {
  const url = dealWithUrl('/api/store-migrate-logs', params);
  return request(url);
}

// 商品管理 - 迁移日志log层
export async function fetchMigrateLogDetail(id) {
  return request(`/api/store-migrate-logs/${id}?light=1`);
}

// 商品管理 - 迁移日志detail层
export async function fetchMigrateLogDetailData(params = {}) {
  const { logId } = params;
  const { id } = params;
  delete params.logId; // eslint-disable-line
  delete params.id; // eslint-disable-line
  const url = dealWithUrl(`/api/store-migrate-logs/${logId}/logs/${id}/details`, params);
  return request(url);
}

// 商品管理 - 迁移创建
export async function migrateStore(params) {
  return request('/api/store-migrate-logs', {
    method: 'POST',
    data: params,
  });
}

// BM管理员列表
export async function fetchBmAdmin(params) {
  const url = dealWithUrl('/api/user/bm_admin', params);
  return request(url);
}

// 更新部门信息
export async function updatePart(params) {
  return request(`/api/part/${params.id}`, {
    method: 'PUT',
    data: params,
  });
}

// 账户资源组 - 像素申请重试
export async function pixelApplyRetry(params) {
  return request('/api/asset/pixel_apply_retry', {
    method: 'POST',
    data: params,
  });
}

// 工单报表获取各类型工单统计数据
export async function fetchOrderReport(params) {
  const url = dealWithUrl('/api/error_msg/statistics', params);
  return request(url);
}

// 商品管理 - 迁移创建 - 检查迁移记录
export async function fetchMigrateStoreLogs(params) {
  const url = dealWithUrl('/api/store-migrate-service/checking', params);
  return request(url);
}

// 谷歌分析-获取店铺授权情况列表接口
export async function getAnalyticsAuth(params) {
  return request('/api/google_analytics/authorization', { params });
}

// 谷歌分析-店铺授权
export async function authAnalyticsStore(data) {
  return request.post('/api/google_analytics/authorization', { data });
}

// 谷歌分析- 数据展示
export async function getAnalyticsData(params) {
  return request('/api/google_analytics/data', { params });
}

// 谷歌分析- 配置接口
export async function getAnalyticsConfigs(params) {
  return request('/api/google_analytics/configurations', { params });
}

//
// 谷歌分析- 可用配置项接口
export async function getAnalyticsOptionalConfigs(params) {
  return request('/api/google_analytics/optional_configurations', { params });
}

// 谷歌分析- 配置更新
export async function updateAnalyticsOptionalConfigs(data) {
  return request('/api/google_analytics/configurations', { method: 'PUT', data });
}

// 谷歌分析- 更新GA账号
export async function syncGoogleAnalyticsProperty(storeId) {
  return request('/api/google_analytics/ga_property', {
    method: 'PUT',
    data: { store_id: storeId },
  });
}

// 毛利核算批量更新下载模板
export async function downProfitExcelModel() {
  return request(`/api/statistical/update_profit/excel`);
}

// 毛利核算批量更新
export async function batchUpdateProfit(params) {
  return request('/api/statistical/batch_update_profit', {
    method: 'POST',
    data: params,
  });
}

// 广告户管理 - 删除账户归属人员记录
export async function deleteAdPeople(params) {
  return request('/api/adaccount_people', {
    method: 'DELETE',
    data: params,
  });
}

// 店铺管理 - 删除店铺归属人员记录
export async function deleteStorePeople(params) {
  return request('/api/store_username_log', {
    method: 'DELETE',
    data: params,
  });
}

// 用户管理
export async function deleteUserStaff(params) {
  return request('/api/users/staff', {
    method: 'DELETE',
    data: params,
  });
}

// 人员下BM回收
export async function recycleBm(params) {
  return request('/api/peoples/bm_recycle', {
    method: 'POST',
    data: params,
  });
}

// 对比分析请求数据
export async function fetchComparedData(params) {
  const url = dealWithUrl('/api/order/compare', params);
  return request(url);
}
// export async function fetchComparedData(params) {
//   return request('/api/order/compare', {
//     method: 'POST',
//     data: params,
//   });
// }

// 资产关系 - 获取API邮箱
export async function fetchApiEmail(params) {
  const url = dealWithUrl('/api/asset/api_email', params);
  return request(url);
}

// 删除扩展属性
export async function deleteStoreMeta(params) {
  return request('/api/store_meta', {
    method: 'DELETE',
    data: params,
  });
}

// 更新店铺拓展信息
export async function updateMeta(params) {
  return request('/api/store_meta', {
    method: 'PUT',
    data: params,
  });
}

// 更新店铺拓展信息
export async function addMeta(params) {
  return request('/api/store_meta', {
    method: 'POST',
    data: params,
  });
}

// 资产关系 - 获取个人号邮箱配置文件URL及Cookie
export async function fetchEmailConfig(params) {
  const url = dealWithUrl('/api/asset/email_config', params);
  return request(url);
}

// wshop加购数据报表
export async function fetchWshopAddCart(params) {
  return request('/api/stores/wshop_add_cart', {
    method: 'POST',
    data: params,
  });
}

export function getProxiedOSSUrl(url) {
  return url
    .replace('https://analysis-bi-apps.oss-cn-shenzhen.aliyuncs.com/', '/oss3/')
    .replace('https://analysis-storage-apps.oss-cn-shenzhen.aliyuncs.com/', '/oss/')
    .replace('http://172.16.12.250:30005/', '/oss_dev/');
}

// 阿里云OSS文件下载
export async function fetchOssFile(url) {
  return request(
    getProxiedOSSUrl(
      url
        .replace('https://analysis-bi-apps.oss-cn-shenzhen.aliyuncs.com/', '/oss3/')
        .replace('https://analysis-storage-apps.oss-cn-shenzhen.aliyuncs.com/', '/oss/')
        .replace('http://172.16.12.250:30005/', '/oss_dev/')
        .replace('https://bigdata-export.oss-cn-shenzhen.aliyuncs.com/', '/big_data_oss/'),
    ),
    {
      responseType: 'blob',
      headers: { Authorization: 'ignore' },
    },
  );
}
// 资产管理 - 设置邮箱验证key
export async function addEmailVerifyKey(params) {
  return request('/api/asset/email_verify_key', {
    method: 'POST',
    data: params,
  });
}
// 资产管理 - 获取验证码
export async function getVerificationCode(params) {
  return request('/api/asset/get_verification_code', {
    params,
  });
}

// 资产管理 - 获取双重验证
export async function fetchVerifyKey(params) {
  const url = dealWithUrl('/api/asset/email_verify_key', params);
  return request(url);
}

// 素材派单 - 根据筛选条件获取剪辑任务
export async function fetchClipTasks(params) {
  return request('/api/clip_tasks', { params });
}

// 素材派单 - 获取所有可选的设计师
export async function fetchParamsOvEditor(params) {
  return request('/api/clip_tasks/params_ov/editor', { params });
}

// 素材派单 - 根据筛选条件获取我的剪辑任务
export async function fetchMeClipTasks(params) {
  return request('/api/me/clip_tasks', { params });
}

// 素材派单 - 接单状态
export async function fetchMeRctStatus() {
  return request('/api/me/rct_status');
}

// 素材派单 - 更新接单状态
export async function updateMeRctStatus(data) {
  return request('/api/me/rct_status', {
    method: 'put',
    data,
  });
}

// 素材派单 - 提交剪辑任务
export async function submitClipTasks(data) {
  return request('/api/clip_tasks', {
    method: 'post',
    data,
  });
}

// 素材派单 - 更新评价
export async function updateClipTasksComment({ id, ...data }) {
  return request(`/api/clip_tasks/${id}/evaluate`, {
    method: 'put',
    data,
  });
}

// 素材派单 - 更新任务状态
export async function updateClipTasksStatus({ id, ...data }) {
  return request(`/api/clip_tasks/${id}/status`, {
    method: 'put',
    data,
  });
}

// 素材派单 - 更新任务状态
export async function deleteClipTasks(data) {
  return request('/api/clip_tasks', {
    method: 'DELETE',
    data,
  });
}

// 素材派单 - 更新剪辑任务
export async function updateClipTasks(data) {
  return request('/api/clip_tasks', {
    method: 'PUT',
    data,
  });
}

// 素材派单 - 导出 EXCEL 的 url (设计端)
export async function fetchExportMeClipTasks(params) {
  return request('/api/me/clip_tasks/export', { params });
}

// 素材派单 - 导出 EXCEL 的 url (运营端)
export async function fetchExportClipTasks(params) {
  return request('/api/clip_tasks/export', { params });
}

// 店铺管理店铺域名日志
export async function fetchDomainNamePPLog(params) {
  const url = dealWithUrl('/api/stores/third_domain_logs', params);
  return request(url);
}

export async function fetchStorePPLog(params) {
  const url = dealWithUrl('/api/stores/store_paypal_logs', params);
  return request(url);
}

export async function fetchSystemPPLog(params) {
  const url = dealWithUrl('/api/stores/system_paypal_logs', params);
  return request(url);
}

// 店铺管理绑定PP收款账号
export async function batchBindPP(params) {
  return request('/api/stores/bind_paypal_payment', {
    method: 'POST',
    data: params,
  });
}

// 资产管理 - 资产授权到邮箱
export async function mandateToEmail(params) {
  return request('/api/asset/mandate_to_email', {
    method: 'POST',
    data: params,
  });
}

// PP列表获取页面数据
export async function fetchPPList(params) {
  const url = dealWithUrl('/api/pp_info', params);
  return request(url);
}
// PP列表 删除
export async function removePPList(params) {
  const { id } = params;
  return request(`/api/pp_info/${id}`, {
    method: 'DELETE',
    data: params,
  });
}
// PP列表创建
export async function addPPList(params) {
  return request('/api/pp_info', {
    method: 'POST',
    data: params,
  });
}

// PP列表更新
export async function updatePPList(params) {
  const { id } = params;
  return request(`/api/pp_info/${id}`, {
    method: 'PUT',
    data: params,
  });
}
// PP域名获取数据
export async function fetchPPDomainName(params) {
  const url = dealWithUrl('/api/domain_change_logs', params);
  return request(url);
}

// 域名管理查看域名使用情况
export async function fetchDomainNameUsage(params) {
  return request('/api/domain_change_logs/domain_details', {
    method: 'get',
    params,
  });
}

// 健康检查
export async function fetchPPhealth(params) {
  const url = dealWithUrl('/api/pp_health', params);
  return request(url);
}

// 店铺管理绑定worldpay收款账号
export async function batchBindWorldpay(params) {
  return request('/api/stores/bind_card2_payment', {
    method: 'POST',
    data: params,
  });
}

// 域名黑名单-查询
export async function fetchBlockDomain(params) {
  return request('/api/block_domains', {
    method: 'get',
    params,
  });
}

// 域名黑名单-删除
export async function deleteBlockDomain(data) {
  return request('/api/block_domains', {
    method: 'delete',
    data,
  });
}

// 域名黑名单-修改
export async function updateBlockDomain({ id, ...data }) {
  return request(`/api/block_domains/${id}`, {
    method: 'put',
    data,
  });
}

// 域名黑名单-添加
export async function addBlockDomain(data) {
  return request('/api/block_domains', {
    method: 'post',
    data,
  });
}
// 更新店铺PP账户
export async function batchUpdatePP(data) {
  return request('/api/stores/update_paypal_payment', {
    method: 'POST',
    data,
  });
}

// 获取旧BM移户申请
export async function fetchOldBMChange(params) {
  return request('/api/asset/old_bm_change_list', {
    method: 'get',
    params,
  });
}

// 创建旧Bm移户申请
export async function addBmChange(data) {
  return request('/api/asset/old_bm_change', {
    method: 'post',
    data,
  });
}

// 更新旧Bm移户申请
export async function updateOldBm(data) {
  return request('/api/asset/old_bm_change', {
    method: 'put',
    data,
  });
}

// 获取邮箱双重验证列表
export async function fetchDoubleVerify(params) {
  return request('/api/email/double_verify', {
    method: 'get',
    params,
  });
}

// 创建邮箱双重验证
export async function addDoubleVerify(data) {
  return request('/api/email/double_verify', {
    method: 'post',
    data,
  });
}

// 更新邮箱双重验证
export async function updateDoubleVerify(data) {
  return request('/api/email/double_verify', {
    method: 'put',
    data,
  });
}

// 获取双重验证的邮箱信息
export async function fetchEmailInfo(params) {
  return request('/api/email/email_info', {
    method: 'get',
    params,
  });
}

// 获取双重验证的表格模板
export async function fetchDoubleVerifyTemplate() {
  return request('/api/email/double_verify/template');
}

// 获取个人号邮箱密钥信息
export async function fetchAssetEmailInfo(params) {
  return request('/api/asset/email_info', {
    method: 'get',
    params,
  });
}

// 获取更换BM API账号申请列表
export async function fetchBmApiChange(params) {
  return request('/api/asset/bm_api_change', {
    method: 'get',
    params,
  });
}

// 店铺管理更新店铺邮箱
export async function updateAdminSserviceEmail(params) {
  return request('/api/stores/admin_service_email', {
    method: 'PUT',
    params,
  });
}

// 获取店铺管理人员变更日志数据
export async function fetchUserNameLogs(params) {
  return request('/api/stores/username_logs', {
    method: 'get',
    params,
  });
}

// 创建BM API账号更换申请
export async function addBmApiChange(data) {
  return request('/api/asset/bm_api_change', {
    method: 'post',
    data,
  });
}

// 广告账号转移
export async function transferAccount(data) {
  return request('/api/asset/ad_account/transfer', {
    method: 'post',
    data,
  });
}

// 像素取消分配(解除绑定)
export async function unAssignPixel(data) {
  return request('/api/asset/pixel/un_assign', {
    method: 'post',
    data,
  });
}

// 账户资源组 - 像素取消关联重试
export async function pixelUnassignRetry(params) {
  return request('/api/asset/pixel/un_assign_retry', {
    method: 'POST',
    data: params,
  });
}

// 素材操作员列表
export async function fetchDealAdReviews(params) {
  return request('/api/user/deal_ad_reviews', {
    method: 'get',
    params,
  });
}

// 获取物流信息导入的表格模板
export async function fetchTrackUploadTemplate() {
  return request('/api/fulfillments/track_upload_template');
}

// 物流信息导入
export async function importTrackOrder(params) {
  return request('/api/fulfillments/order_import', {
    method: 'POST',
    data: params,
  });
}
// 店铺管理绑定和更新XborderPay收款方式
export async function batchBindXborderpay(params) {
  return request('/api/stores/bind_card1_payment', {
    method: 'POST',
    data: params,
  });
}
// 店铺管理关闭XborderPay收款方式
export async function batchCloseXborderpay(params) {
  return request('/api/stores/close_card1_payment', {
    method: 'POST',
    data: params,
  });
}
// 店铺管理关闭worldpay收款方式
export async function batchCloseWorldpay(params) {
  return request('/api/stores/close_card2_payment', {
    method: 'POST',
    data: params,
  });
}
// 店铺管理关闭PP收款方式
export async function batchClosePP(params) {
  return request('/api/stores/close_paypal_payment', {
    method: 'POST',
    data: params,
  });
}
// 批量修改页面获取操作人列表
export async function fetchOperator(params) {
  return request('/api/has_update_pp_auth_users', {
    method: 'get',
    params,
  });
}
// 批量修改页面获取日志
export async function fetchBatchList(params) {
  return request('/api/sync_ws_pp_logs', {
    method: 'get',
    params,
  });
}
// 批量修改页面上传excel
export async function bacthUpload(params) {
  return request('/api/batch_store_pp', {
    method: 'POST',
    data: params,
  });
}
// 批量修改模板
export async function BatchListTemplate(params) {
  return request('/api/batch_excel_example ', {
    method: 'get',
    params,
  });
}
// 批量修改导出Excel
export async function BatchDownLoad(params) {
  return request('/api/sync_ws_pp_logs/download', {
    method: 'get',
    params,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
// 收款渠道分析
export async function getCollectionList(params) {
  return request('/api/order/payment_way/analysis', {
    method: 'get',
    params,
  });
}
// 更新或批量更新worldpay
export async function batchUpdateWorldpay(params) {
  return request('/api/stores/update_card2_payment', {
    method: 'POST',
    data: params,
  });
}
// 更新或批量更新xborderpay
export async function batchUpdateXborderpay(params) {
  return request('/api/stores/update_card1_payment', {
    method: 'POST',
    data: params,
  });
}

// 更新店铺货币和时区
export async function updateCurrencyTimezone(params) {
  return request('/api/store/currency_and_timezone', {
    method: 'PUT',
    data: params,
  });
}
// 获取XB账号管理
export async function fetchXbAccount(params) {
  return request('/api/xb_info', {
    method: 'get',
    params,
  });
}
// 更新XB账号管理
export async function updateXbAccount(params) {
  const { id } = params;
  return request(`/api/xb_info/${id}`, {
    method: 'PUT',
    data: params,
  });
}
// 新增XB账号管理
export async function addXbAccount(params) {
  return request('/api/xb_info', {
    method: 'POST',
    data: params,
  });
}
// 删除XB账号管理
export async function deleteXbAccount(id) {
  return request(`/api/xb_info/${id}`, {
    method: 'DELETE',
  });
}

// 获取WP账号管理
export async function fetchWpAccount(params) {
  return request('/api/wp_info', {
    method: 'get',
    params,
  });
}
// 更新WP账号管理
export async function updateWpAccount(params) {
  const { id } = params;
  return request(`/api/wp_info/${id}`, {
    method: 'PUT',
    data: params,
  });
}
// 新增WP账号管理
export async function addWpAccount(params) {
  return request('/api/wp_info', {
    method: 'POST',
    data: params,
  });
}
// 删除WP账号管理
export async function deleteWpAccount(id) {
  return request(`/api/wp_info/${id}`, {
    method: 'DELETE',
  });
}
// 获取worldpay的merchant列表
export async function getWorldpayMerchant(params) {
  return request('/api/wp_info_list', {
    method: 'get',
    params,
  });
}
// 获取xborderpay的merchant列表
export async function getXborderpayMerchant(params) {
  return request('/api/xb_info_list', {
    method: 'get',
    params,
  });
}
// 收款渠道分析导出Excel
export async function downLoadPaymentWay(params) {
  return request('/api/order/payment_way/analysis/download', {
    method: 'get',
    params,
  });
}

// page列表
export async function fetchPageManager(data) {
  return request('/api/asset/page', {
    method: 'POST',
    data,
  });
}

// 获取主页下的所有申请工单
export async function fetchPageApply(params) {
  return request('/api/asset/page/apply', {
    method: 'get',
    params,
  });
}

// 运营争议
export async function fetchOperationDispute(params) {
  return request('/api/store/dispute', {
    method: 'get',
    params,
  });
}

// 域名收款
export async function fetchDomainNamePayment(params) {
  return request('/api/domain/receive_payment', {
    method: 'get',
    params,
  });
}

// 域名收款导出
export async function downloadDomainName(params) {
  return request('/api/domain/receive_payment/export', {
    method: 'get',
    params,
  });
}

// 下架SKU操作员列表
export async function fetchUnShelveUser(params) {
  return request('/api/user/un_shelve_sku', {
    method: 'get',
    params,
  });
}

//  获取资产消耗报表
export async function fetchAssetConsumption(params) {
  return request('/api/approve/asset/consumption', {
    method: 'get',
    params,
  });
}
//  资产消耗每日明细报表
export async function fetchAssetConsumptionDetail(params) {
  return request('/api/approve/asset/consumption/details', {
    method: 'get',
    params,
  });
}

// 修改广告账户名称
export async function changeAccountName(params) {
  return request('/api/adaccount/change_name', {
    method: 'POST',
    data: params,
  });
}

// 创建SKU核算导出任务
export async function exportJob(params) {
  return request('/api/sale_calculate/export_job', {
    method: 'POST',
    data: params,
  });
}

// 获取SKU核算导出任务列表
export async function fetchSkuCalcExportTask(params) {
  return request('/api/sale_calculate/export_job', {
    method: 'get',
    params,
  });
}

//  获取SKU核算下载链接
export async function fetchSkuCalcExportUri(params) {
  return request('/api/sale_calculate/export_uri', {
    method: 'get',
    params,
  });
}

// 创建毛利核算导出任务
export async function statisticalExportJob(data) {
  return request('/api/statistical/export_job', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

// 获取毛利核算导出任务列表
export async function fetchStatisticalExportJob(params) {
  return request('/api/statistical/export_job', {
    method: 'get',
    params,
  });
}

//  获取毛利核算下载链接
export async function fetchStatisticalExportUri(params) {
  return request('/api/statistical/export_uri', {
    method: 'get',
    params,
  });
}

//  获取库存SKU
export async function fetchStockSkus(params) {
  return request('/api/stock-skus', {
    method: 'get',
    params,
  });
}

// 导入库存SKU
export async function importStock(data) {
  return request('/api/stock-skus', {
    method: 'POST',
    data,
  });
}

// 获取虚拟/真实物流单号
export async function fetchVirtualRealTrack(data) {
  return request('/api/fulfillments/virtual_real_track', {
    method: 'POST',
    data,
  });
}

// 更新BM属性
export async function changeBmLevel(data) {
  return request('/api/asset/bm', {
    method: 'PUT',
    data,
  });
}

//  获取Bm端口变更日志
export async function fetchProxyLogs(params) {
  return request('/api/asset/bm/proxy_port_change_logs', {
    method: 'get',
    params,
  });
}

// 更新店铺店铺FB域名验证
export async function changeDomainVerification(data) {
  return request('/api/store/fb_domain_verification', {
    method: 'PUT',
    data,
  });
}
// 店铺管理获取主题列表
export async function fetchStoreTheme() {
  return request('/api/store/theme/list', {
    method: 'get',
  });
}
// 获取paypal子账号
export async function fetchSubAccount(params) {
  return request('/api/paypal/sub_account', {
    method: 'get',
    params,
  });
}

// 删除子账号
export async function deleteSubAccount(data) {
  return request('/api/paypal/sub_account', {
    method: 'DELETE',
    data,
  });
}

// 添加子账号
export async function addSubAccount(data) {
  return request('/api/paypal/sub_account', {
    method: 'POST',
    data,
  });
}

// 更新子账号
export async function editSubAccount(data) {
  return request('/api/paypal/sub_account', {
    method: 'PUT',
    data,
  });
}

// 子账号解绑用户
export async function deleteSubAccountUser(data) {
  return request('/api/paypal/sub_account/detach_user', {
    method: 'POST',
    data,
  });
}

// 子账号绑定用户
export async function attachSubAccountUser(data) {
  return request('/api/paypal/sub_account/attach_user', {
    method: 'POST',
    data,
  });
}

// 导入paypal子账号
export async function uploadPayPalListExcel(data) {
  return request('/api/paypal/sub_account/import', {
    method: 'POST',
    data,
  });
}

// 获取导入Paypal子账号Excel模板
export async function fetchTemplatePPListExcel() {
  return request('/api/paypal/sub_account/import_template', {
    method: 'get',
  });
}

// 获取用户选择列表
export async function fetchUserOptions(params) {
  return request('/api/users/user_options', {
    method: 'get',
    params,
  });
}

// 获取VPS信息
export async function fetchAssetVps(data) {
  return request('/api/asset/vps_info', {
    method: 'POST',
    data,
  });
}
// 批量更新BM Level
export async function updateAssetBmLevel(data) {
  return request('/api/asset/bm/update_level', {
    method: 'PUT',
    data,
  });
}
// PP家族争议
export async function fetchPPFamilyDispute(params) {
  return request('/api/paypal/dispute', {
    method: 'get',
    params,
  });
}
// PP家族争议导出
export async function downloadPPFamilyDispute(params) {
  return request('/api/paypal/dispute/export', {
    method: 'get',
    params,
  });
}
// 广告账户解绑
export async function recycleAccount(data) {
  return request('/api/asset/account/recycle', {
    method: 'POST',
    data,
  });
}

// 主页解绑
export async function recyclePage(data) {
  return request('/api/asset/page/recycle', {
    method: 'POST',
    data,
  });
}

// 店铺管理绑定PPC收款账号
export async function batchBindPPC(params) {
  return request('/api/stores/bind_ppc_payment', {
    method: 'POST',
    data: params,
  });
}
// 店铺管理更新PPC收款账号
export async function batchUpdatePPC(params) {
  return request('/api/stores/update_ppc_payment', {
    method: 'POST',
    data: params,
  });
}

// 店铺管理关闭PPC收款账号
export async function batchClosePPC(params) {
  return request('/api/stores/close_ppc_payment', {
    method: 'POST',
    data: params,
  });
}

// 开始处理主页申请
export async function appyleProcess(data) {
  return request('/api/asset/page/process_apply', {
    method: 'POST',
    data,
  });
}
// 重置wshop店铺token
export async function resetWSToken(data) {
  return request('/api/store/wshop_reset_token', {
    method: 'POST',
    data,
  });
}

// 审核人列表
export async function fetchReviewPeople() {
  return request('/api/modify_sku/purchases', {
    method: 'get',
  });
}

// 域名列表
export async function fetchDomainList(payload) {
  return request('/api/domain/list', {
    method: 'POST',
    params: payload.params,
    data: payload?.data || payload,
  });
}
// 更新域名
export async function updateDomainName(params) {
  const { id } = params;
  delete params.id;
  return request(`/api/domains/${id}`, {
    method: 'PUT',
    data: params,
  });
}

// 批量更新域名
export async function batchUpdateDomainName(data) {
  return request(`/api/domains/batch`, {
    method: 'PUT',
    data,
  });
}

// 新增域名
export async function addDomainName(params) {
  return request(`/api/domains`, {
    method: 'POST',
    data: params,
  });
}
// 获取像素成效
export async function fetchAssetsPixel(params) {
  return request('/api/asset/pixel/effective', {
    method: 'get',
    params,
  });
}

// 获取像素成效趋势
export async function fetchAssetsPixelTrend(params) {
  return request('/api/asset/pixel/effective_trend', {
    method: 'get',
    params,
  });
}
// 运营广告账号
export async function fetchWorkOrderAccount(params) {
  return request('/api/people/ad_accounts', {
    method: 'get',
    params,
  });
}
// 运营店铺列表
export async function fetchWorkOrderDomain(params) {
  return request('/api/people/domains', {
    method: 'get',
    params,
  });
}
// 运营bm列表
export async function fetchWorkOrderBms(params) {
  return request('/api/people/bms', {
    method: 'get',
    params,
  });
}
// bm下的像素id
export async function fetchWorkOrderPixels(params) {
  return request('/api/people/pixels', {
    method: 'get',
    params,
  });
}
// bm下的page_id
export async function fetchWorkOrderPages(params) {
  return request('/api/people/pages', {
    method: 'get',
    params,
  });
}

// 查询trackhub单号结果
export async function trackingHubSearch(params) {
  return request(`/api/logistics/tracking_hub/search`, {
    method: 'POST',
    data: params,
  });
}

// 建议反馈
export async function fetchFeedback(params) {
  return request('/api/suggest', {
    method: 'GET',
    params,
  });
}
export async function updateFeedback(params) {
  const { id } = params;
  return request(`/api/suggest/${id}`, {
    method: 'PUT',
    data: params,
  });
}
export async function addFeedback(params) {
  return request('/api/suggest', {
    method: 'POST',
    data: params,
  });
}
// PP告警
export async function fetchPPAlert(params) {
  return request('/api/pp_alarm', {
    method: 'GET',
    params,
  });
}

// 获取商品推荐所有的分类接口
export async function fetchScmCategories() {
  return request('/api/scm/categories', {
    method: 'GET',
  });
}

// 推荐商品列表
export async function fetchScmRecommend(params) {
  return request('/api/scm/recommend_products', {
    method: 'GET',
    params,
  });
}
// 批量分配账号
export async function batchAssignAccount(params) {
  return request('/api/asset/batch_assign_account', {
    method: 'POST',
    data: params,
  });
}
// 获取定制产品详情页
export async function fetchCustomizedProduct({ vSku }) {
  return request(`/api/customize_products`, {
    method: 'GET',
    params: { v_sku: vSku },
  });
}
// 采购修改虚拟子sku
export async function updateVsSkuByP(params) {
  return request(`/api/customize_products/vs_sku`, {
    method: 'PUT',
    data: params,
  });
}

// 采购修改采购供应商信息
export async function updatePSupplyByP(params) {
  return request(`/api/customize_products/supply_purchase`, {
    method: 'POST',
    data: params,
  });
}

// 采购修改运营供应商信息
export async function updatGSupplyByP(params) {
  return request(`/api/customize_products/supply_operation_by_purchase`, {
    method: 'PUT',
    data: params,
  });
}
// 运营新增或修改子sku
export async function addVsSkuByG(params) {
  return request(`/api/customize_products/vs_sku`, {
    method: 'POST',
    data: params,
  });
}

// 删除虚拟sku
export async function deleteVsSku(params) {
  return request('/api/customize_products/vs_sku', {
    method: 'DELETE',
    data: params,
  });
}
// 运营新增修改供应商信息
export async function updateSupplyByG(params) {
  return request(`/api/customize_products/supply_operation`, {
    method: 'POST',
    data: params,
  });
}
// 删除货源信息
export async function deleteSupply(params) {
  return request('/api/customize_products/supply', {
    method: 'DELETE',
    data: params,
  });
}

// 添加落地页图片
export async function addLandingPageImage(params) {
  return request(`/api/customize_products/landing_image`, {
    method: 'POST',
    data: params,
  });
}
// 删除落地页图片
export async function deleteLandingPageImage(params) {
  return request('/api/customize_products/landing_image', {
    method: 'DELETE',
    data: params,
  });
}
// 落地页列表
export async function fetchLandingPage(params) {
  return request('/api/landing_page', {
    method: 'GET',
    params,
  });
}

// 落地页配置
export async function fetchLandingPageConfig(params) {
  return request('/api/landing-page-config', {
    method: 'GET',
    params,
  });
}

// 编辑落地页配置
export async function updateLandingPageConfig(data) {
  return request('/api/update-landing-page-config', {
    method: 'POST',
    data,
  });
}

// 获取落地页列表所有的状态
export async function fetchLandingStatus() {
  return request('/api/landing_page/categories', {
    method: 'GET',
  });
}
// 落地页列表详情
export async function fetchLandingPageDetail(params) {
  return request('/api/landing_page/sku_details', {
    method: 'GET',
    params: {
      sort: '-sales',
      ...params,
    },
  });
}
// 更新落地页列表状态和备注
export async function updateLandingDetail(data) {
  return request(`/api/landing_page/batch_update`, {
    method: 'POST',
    data,
  });
}

// 更新落地页列表货源链接,图片
export async function updateLandingLinks(data) {
  return request(`/api/landing_page/update_links`, {
    method: 'POST',
    data,
  });
}
// 删除建议反馈
export async function deleteSuggest({ id }) {
  return request(`/api/suggest/${id}`, {
    method: 'DELETE',
  });
}
// 建议反馈回复
export async function replyAdvice(params) {
  return request('/api/suggest/reply', {
    method: 'POST',
    params,
  });
}
// 采购批量修改运营供应商模板
export async function supplyTemplateByP() {
  return request('/api/customize_products/supply_operation_by_purchase_template');
}
// 采购批量增加供应商模板
export async function supplyTemplateByPSelf() {
  return request('/api/customize_products/supply_purchase_template');
}
// 运营批量增加供应商模板
export async function supplyTemplateByG() {
  return request('/api/customize_products/supply_operation_template');
}
// 运营批量sku模板
export async function skuTemplateByG() {
  return request('/api/customize_products/batch_sku_template');
}
// 运营批量修改或新增产品属性
export async function uploadAttrByG(params) {
  return request('/api/customize_products/batch_add_vs_sku', {
    method: 'POST',
    data: params.payload,
  });
}
// 运营批量修改或新增供应商
export async function uploadSkuSupplyOrAttrByG(params) {
  return request('/api/customize_products/batch_supply_operation', {
    method: 'POST',
    data: params.payload,
  });
}
// 采购批量增加供应商
export async function uploadSkuSupplyByP(params) {
  return request('/api/customize_products/batch_supply_purchase', {
    method: 'POST',
    data: params.payload,
  });
}
// 资产审核 - page
export async function fetchAssetReviewPage(data) {
  return request('/api/asset-review/pages', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// 资产审核 - 着陆页
export async function fetchAssetReviewLanding(data) {
  return request('/api/asset-review/landing', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// 资产审核 - domain
export async function fetchAssetReviewDomain(data) {
  return request('/api/asset-review/domains', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// 资产审核修改
export async function updatePageReview(id, data) {
  return request(`/api/asset-review/pages/${id}`, {
    method: 'PUT',
    data,
  });
}
// 资产审核修改
export async function updateLandingReview(id, data) {
  return request(`/api/asset-review/landing/${id}`, {
    method: 'PUT',
    data,
  });
}
// 资产审核修改
export async function updateDomainReview(id, data) {
  return request(`/api/asset-review/domains/${id}`, {
    method: 'PUT',
    data,
  });
}
// 被删除的供应商信息
export async function fetchDeletedSupply(params) {
  return request('/api/customize_products/supply_deleted', {
    method: 'GET',
    params,
  });
}
// 还原被删除的供应商
export async function revertDeletedSupply(data) {
  return request('/api/customize_products/revert_supply', {
    method: 'POST',
    data,
  });
}
// 被删除的产品属性信息
export async function fetchDeletedAttr(params) {
  return request('/api/customize_products/vs_sku_deleted', {
    method: 'GET',
    params,
  });
}
// 还原被删除的产品属性
export async function revertDeletedAttr(data) {
  return request('/api/customize_products/revert_vs_sku', {
    method: 'POST',
    data,
  });
}

// 域名管理 - 存放账号
export async function fetchDomainAccountOption() {
  return request('/api/domain/account-options', {
    method: 'GET',
  });
}
// 店铺争议
export async function fetchStoreDispute(data) {
  return request('/api/store/dispute/only', {
    method: 'POST',
    data,
  });
}

// 处理人处理建议反馈
export async function handleFeedback(data) {
  return request('/api/suggest/reply', {
    method: 'POST',
    data,
  });
}
// 回复人回复建议反馈
export async function replyFeedback(data) {
  return request('/api/suggest/chat', {
    method: 'POST',
    data,
  });
}
// 文字转图片
export async function fetchText2Image(data) {
  return request('/api/tools/text2image', {
    method: 'POST',
    data,
  });
}
// 定制产品管理
export async function fetchCustomizedProducts(params) {
  return request('/api/customize_products/index', {
    method: 'GET',
    params,
  });
}
// 定制产品管理
export async function fetchCustomizedAlarms(params) {
  return request('/api/customize_products/alarms', {
    method: 'GET',
    params,
  });
}
// 定制产品管理告警
export async function fetchBmsEmails(params) {
  return request('/api/people/bms_emails', {
    method: 'GET',
    params,
  });
}
// 批量更新系统PP
export async function batchUpdateSystemPP(data) {
  return request('/api/batch/store/system_pp', {
    method: 'PUT',
    data,
  });
}

// 修改账号资源组 page 备注
export async function editAssetRemark(id, data) {
  return request(`/api/asset/page_apply/${id}`, {
    method: 'PUT',
    data,
  });
}

// trunkhub新的物流单号
export async function generateTrackinghubNumber(data) {
  return request('/api/logistics/tracking_hub/generate', {
    method: 'POST',
    data,
  });
}

// 像素归属查询
export async function fetchPixelQuery(params) {
  return request('/api/pixel/belong', {
    method: 'GET',
    params,
  });
}

// 爱马仕-用户管理
export async function fetchHermesUser(params) {
  return request('/api/hermes/users', {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}

// 爱马仕-用户管理 新增
export async function addHermesUser(name) {
  return request(`/api/hermes/user/${name}/sync`, {
    method: 'POST',
  });
}

// 爱马仕-用户管理 修改
export async function updateHermesUser(id, data) {
  return request(`/api/hermes/user/${id}/p-r`, {
    method: 'POST',
    data,
  });
}

// 爱马仕-用户管理
export async function fetchHermesPR() {
  return request('/api/hermes/role-permission-options');
}

// 域名管理店铺来源
export async function fetchStoreSource() {
  return request('/api/domain/types', {
    method: 'GET',
  });
}

// 域名使用类型-各部门名称
export async function fetchDomainUsage() {
  return request('/api/domains/usages', {
    method: 'GET',
  });
}

// 获取域名汇总金额
export async function fetchDomainTotalAmount(payload) {
  return request('/api/domains/spend', {
    method: 'POST',
    params: payload.params,
    data: payload?.data || payload,
  });
}

// 导出域名
export async function exportDomains(payload) {
  return request('/api/domains/excel', {
    method: 'POST',
    params: payload.params,
    data: payload?.data || payload,
  });
}

// 导入域名-模板
export async function downloadDomainsTemplate() {
  return request('/api/domains/template/excel');
}

// 批量创建域名-来自excel
export async function importDomains(data) {
  return request('/api/domains/batch', {
    method: 'POST',
    data,
  });
}

// 爱马仕-用户授权
export async function authorizeHermes(token, clientName = 'hermes') {
  return fetch(`/api/authorize/${clientName}/path`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.json());
}

// 店铺工单
export async function fetchShopTicket(params) {
  return request('/api/store_ticket', {
    method: 'GET',
    params,
  });
}

export async function fetchShopTicketStores() {
  return request('/api/store_ticket/stores', {
    method: 'GET',
  });
}
export async function fetchShopTicketTypes() {
  return request('/api/store_ticket/types', {
    method: 'GET',
  });
}
export async function fetchSpecificStore(params) {
  return request('/api/store_ticket/stores', {
    method: 'GET',
    params,
  });
}

export async function createOrUpdateStoreTicket(data) {
  return request('/api/store_ticket', {
    method: 'POST',
    data,
  });
}
// 处理店铺工单
export async function DealShopTicket(data) {
  return request('/api/store_ticket/reply', {
    method: 'POST',
    data,
  });
}
// 店铺工单-提交人回复
export async function replyStoreTicket(data) {
  return request('/api/store_ticket/chat', {
    method: 'POST',
    data,
  });
}
// 域名日志
export async function fetchThirdDomainLog(params) {
  return request('/api/third_domain_logs', {
    method: 'GET',
    params,
  });
}

// 更新激励库存SKU
export async function updateIncentiveInventorySku(data) {
  const { id } = data;
  return request(`/api/promote_stock_sku/${id}`, {
    method: 'PUT',
    data,
  });
}
// 新增激励库存SKU
export async function addIncentiveInventorySku(data) {
  return request('/api/promote_stock_sku', {
    method: 'POST',
    data,
  });
}
// 删除激励库存SKU
export async function deleteIncentiveInventorySku({ id }) {
  return request(`/api/promote_stock_sku/${id}`, {
    method: 'DELETE',
  });
}
// 批量激励库存SKU模板
export async function downloadSKUExcelTemp(params) {
  return request('/api/batch/promote_stock_sku/excel', {
    method: 'GET',
    params,
  });
}
// 批量导入激励库存SKU模板
export async function batchImportSku(data) {
  return request('/api/batch/promote_stock_sku', {
    method: 'POST',
    data,
  });
}
// 定制产品打开或关闭不推广状态
export async function customizeProductsNoPromote(params) {
  return request('/api/customize_products/no_promote', {
    method: 'PUT',
    data: params,
  });
}
// 店铺争议异步导出
export async function asyncDownloadStoreDisputeJob(data) {
  return request('/api/store/dispute/only/async_export', {
    method: 'POST',
    data,
  });
}

// 信用卡管理
export async function fetchCreditCard(data) {
  return request('/api/credit_card', {
    method: 'POST',
    data,
  });
}
// 信用卡管理导出Excel
export async function exportCreditCard(data) {
  return request('/api/credit_card/export', {
    method: 'POST',
    data,
  });
}
// 信用卡管理更新持卡人
export async function updateCardholder(data) {
  return request('/api/credit_card', {
    method: 'PUT',
    data,
  });
}
// 获取信用卡代理商列表
export async function fetchCardAgents(params) {
  return request('/api/card_agents', {
    method: 'GET',
    params,
  });
}
// 获取持卡类型
export async function fetchCardHolders(params) {
  return request('/api/card_holders', {
    method: 'GET',
    params,
  });
}
// 导出定制产品详情
export async function exportCustomizedProduct(data) {
  return request('/api/customize_products/export', {
    method: 'POST',
    data,
  });
}
// 广告账户统计
export async function fetchadAaccountStatistics(params) {
  return request('/api/ad_account_statistics', {
    method: 'GET',
    params,
  });
}

// 自定义分类
export async function fetchCustomizeCategory(params) {
  return request('/api/customize_category', {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}
// 创建分类
export async function addCustomizeCategory(data) {
  return request('/api/customize_category', {
    method: 'POST',
    data,
  });
}
// 更新分类
export async function updateCustomizeCategory(data) {
  return request('/api/customize_category', {
    method: 'PUT',
    data,
  });
}
// 删除分类
export async function deleteCustomizeCategory(data) {
  return request('/api/customize_category', {
    method: 'DELETE',
    data,
  });
}
// 分类名称
export async function fetchCustomizeCategoryName() {
  return request('/api/customize_category_name', {
    method: 'GET',
  });
}
// 创建人员
export async function fetchCustomizeCategoryOperator() {
  return request('/api/customize_category_operator', {
    method: 'GET',
  });
}
// SKU添加分类
export async function addSKUCustomizeCategory(data) {
  return request('/api/customize_category_sku', {
    method: 'POST',
    data,
  });
}
// SKU删除分类
export async function deleteSKUCustomizeCategory(data) {
  return request('/api/customize_category_sku', {
    method: 'DELETE',
    data,
  });
}
// 广告账户统计详情
export async function fetchStatisticsDetails(params) {
  return request('/api/ad_account_statistics/details', {
    method: 'GET',
    params,
  });
}
// 广告账户统计时区详情
export async function fetchStatisticsTimezone(params) {
  return request('/api/ad_account_statistics/timezone', {
    method: 'GET',
    params,
  });
}
// 广告账户统计导出
export async function fetchStatisticsDetailsExport(params) {
  return request('/api/ad_account_statistics/details/export', {
    method: 'GET',
    params,
  });
}

// 绑定pprest
export async function batchBindPPrest(data) {
  return request('/api/stores/bind_pp_rest', {
    method: 'POST',
    data,
  });
}

// 更新pprest
export async function batchUpdatePPrest(data) {
  return request('/api/stores/update_pp_rest', {
    method: 'POST',
    data,
  });
}

// 关闭pprest
export async function batchClosePPrest(data) {
  return request('/api/stores/close_pp_rest', {
    method: 'POST',
    data,
  });
}

// 关闭PP三方授权
export async function batchClosePPresttr(data) {
  return request('/api/stores/close_pp_rest_tr', {
    method: 'POST',
    data,
  });
}

//
export async function exportIncentiveSku(params) {
  return request('/api/export/promote_stock_sku', {
    method: 'GET',
    params,
  });
}
// 素材审核导出
export async function exportMaterialReview(data) {
  return request('/api/fb-ad/post-info/async/export', {
    method: 'POST',
    data,
  });
}
// DNS解析列表
export async function fetchDnsList(params) {
  return request('/api/domain/dnslist', {
    method: 'GET',
    params,
  });
}
// 修改DNS解析列表
export async function updateDnsList(data) {
  return request('/api/domain/update_dns', {
    method: 'POST',
    data,
  });
}
export async function delDnsList(data) {
  return request('/api/domain/del_dns', {
    method: 'POST',
    data,
  });
}
// 批量导出定制产品
export async function batchExportCustomized(data) {
  return request('/api/customize_products/export_custom_products', {
    method: 'POST',
    data,
  });
}
// 批量分配状态
export async function batchAssignStatus(data) {
  return request('/api/asset/batch_assign_account_status', {
    method: 'POST',
    data,
  });
}

// 物流跟踪
export async function fetchLogisticsTracking(data) {
  return request('/api/fulfillment/tracks', {
    method: 'POST',
    data,
  });
}
// 打开无法定制
export async function clickNotBeCustomized(data) {
  return request('/api/customize_products/not_be_customized', {
    method: 'POST',
    data,
  });
}
// 物流跟踪订单配置
export async function fetchOrderConfiguration(data) {
  return request('/api/ticket/get_order_config', {
    method: 'POST',
    data,
  });
}
export async function fetchTracknum(data) {
  return request('/api/ticket/get_tracknum', {
    method: 'POST',
    data,
  });
}
export async function uppdateTracking(data) {
  return request('/api/fulfillment/tracks', {
    method: 'PUT',
    data,
  });
}
export async function downloadRemarkTemplate(params) {
  return request('/api/ticket/logistics_remark_template', {
    method: 'GET',
    params,
  });
}
export async function batchImportRemark(data) {
  return request('/api/ticket/logistics_remark_import', {
    method: 'POST',
    data,
  });
}

// 订单是否处理
export async function updateOrderProcessed(data) {
  return request('/api/customize_products/order_processed', {
    method: 'POST',
    data,
  });
}
// 修改无法订单原因
export async function EditNotBeCustomizedReason(data) {
  return request('/api/customize_products/not_be_customized_reason', {
    method: 'PUT',
    data,
  });
}

// 资产审核批量整改
export async function batchAssetReview(data) {
  return request('/api/asset-review/batch', {
    method: 'POST',
    data,
  });
}

export async function fetchUnshippedOrders(data) {
  return request('/api/stores/store_order_unfulfilled', {
    method: 'POST',
    data,
  });
}

export async function fetchWsPluginsAlias() {
  return request('/api/ws_plugins/alias', {
    method: 'GET',
  });
}

export async function updateFbEmailPwd(data) {
  return request('/api/fb_email_pwd', {
    method: 'PUT',
    data,
  });
}

export async function fetchRealTimeSpending(params) {
  return request('/api/real_time/ad_spend', {
    method: 'GET',
    params,
  });
}
export async function fetchRealTimeSpendingDetail(params) {
  const { id } = params;
  return request(`/api/real_time/ad_spend/${id}`, {
    method: 'GET',
  });
}
export async function exportDetail(params) {
  const { id } = params;
  return request(`/api/export/real_time/ad_spend/${id}`, {
    method: 'GET',
  });
}

export async function fetchRealTimeAdSpend(data) {
  return request('/api/real_time/ad_spend', {
    method: 'POST',
    data,
  });
}

// 分部门或店铺的订单/销量/销售额趋势
export async function fetchDashTrendByPartOrStore(params) {
  const url = dealWithUrl('/api/dashboard/orders_trend_part_or_store', params);
  return request(url);
}

// 前十的订单量/销售量/销售额
export async function fetchTopTenOrder(params) {
  const url = dealWithUrl('/api/dashboard/top_order_sales', params);
  return request(url);
}

export async function copySupply(data) {
  return request('/api/customize_products/copy_supply', {
    method: 'POST',
    data,
  });
}
// 真实SKU定制产品
export async function fetchTSkuCustomized(params) {
  return request('/api/t_sku_customize_products', {
    method: 'GET',
    params,
  });
}
// 订单物流处理
export async function fetchOrderLogistics(params) {
  return request('/api/ticket/order_logistics_processing', {
    method: 'GET',
    params,
  });
}
// 导出工单提交
export async function exportErrorMsg(data, select) {
  return request('/api/error_msg/export', {
    method: 'POST',
    data,
    removeBlankProperty: true,
    headers: {
      select,
    },
  });
}

// 校验物流处理任务名称
export async function verifyTaskName(params) {
  return request('/api/ticket/verify_task_name', {
    method: 'GET',
    params,
  });
}
// 资产盘点列表
export async function fetchPropertyAssessment(params) {
  return request('/api/inventory/works', {
    method: 'GET',
    params,
  });
}

// 虚拟订单入库导入
export async function importVMOrderLogistics(data) {
  return request('/api/ticket/virtual_track_insert', {
    method: 'POST',
    data,
  });
}
// 疫情物流匹配导入
export async function importYQOrderLogistics(data) {
  return request('/api/ticket/order_match_import', {
    method: 'POST',
    data,
  });
}

// AB单匹配导入
export async function importABOrderLogistics(data) {
  return request('/api/ticket/dispute_track_import', {
    method: 'POST',
    data,
  });
}

// XB单匹配导入
export async function importXBOrderLogistics(data) {
  return request('/api/ticket/dispute_track_import', {
    method: 'POST',
    data,
  });
}
// 正常订单物流匹配导入
export async function importZCOrderLogistics(data) {
  return request('/api/ticket/order_match_import', {
    method: 'POST',
    data,
  });
}

// 每日订单物流匹配导入
export async function importMRDDOrderLogistics(data) {
  return request('/api/ticket/daily_match_import', {
    method: 'POST',
    data,
  });
}

// 订单地址校验导入
export function importDZJYOrderLogistics(data) {
  return request('/api/ticket/order_address_verification_import', {
    method: 'POST',
    data,
  });
}

// 资产盘点详情
export async function fetchInventoryDetail(params) {
  return request('/api/inventory/detail', {
    method: 'GET',
    params,
  });
}
// 完成资产盘点
export async function finshInventory(data) {
  return request('/api/inventory/finished', {
    method: 'POST',
    data,
  });
}

// 每日邮件发送导入
export async function importMRYJOrderLogistics(data) {
  return request('/api/ticket/order_track_email', {
    method: 'POST',
    data,
  });
}

// 导出虚拟订单入库
export async function exportVMOrderLogistics(params) {
  return request('/api/ticket/virtual_track_export', {
    method: 'GET',
    params,
  });
}
// 导出疫情影响匹配
export async function exportYQOrderLogistics(params) {
  return request('/api/ticket/order_match_export', {
    method: 'GET',
    params,
  });
}

// 导出AB单匹配
export async function exportABOrderLogistics(params) {
  return request('/api/ticket/dispute_track_export', {
    method: 'GET',
    params,
  });
}

// 导出XB单匹配
export async function exportXBOrderLogistics(params) {
  return request('/api/ticket/dispute_track_export', {
    method: 'GET',
    params,
  });
}
// 导出正常订单匹配
export async function exportZCOrderLogistics(params) {
  return request('/api/ticket/order_match_export', {
    method: 'GET',
    params,
  });
}

// 导出每日邮件发送
export async function exportMRYJOrderLogistics(params) {
  return request('/api/ticket/order_track_email_export', {
    method: 'GET',
    params,
  });
}

// 导出每日订单匹配
export async function exportMRDDOrderLogistics(params) {
  return request('/api/ticket/daily_match_export', {
    method: 'GET',
    params,
  });
}

// 导出订单地址校验
export function exportDAJYOrderLogistics(params) {
  return request(`/api/ticket/order_address_verification_export`, {
    params,
  });
}

// 订单物流处理下载模板
export async function fetchOrderLogisticsTemplate(params) {
  return request('/api/ticket/order_logistics_template', {
    method: 'GET',
    params,
  });
}
// 更新资产盘点
export async function updateInventory(data) {
  const { id } = data;
  return request(`/api/inventory/${id}`, {
    method: 'PUT',
    data,
  });
}
// 盘点完成情况
export async function fetchInventoryCompleted(params) {
  return request(`/api/inventory/completed/detail`, {
    method: 'GET',
    params,
  });
}

export async function fetchInventories(params) {
  return request('/api/inventories', {
    method: 'GET',
    params,
  });
}
export async function fetchPixel(data) {
  return request('/api/pixels/list', {
    method: 'POST',
    data,
  });
}
export async function updateToken(data) {
  const { id } = data;
  return request(`/api/pixels/${id}`, {
    method: 'PUT',
    data,
  });
}

export async function fetchPixelName(params) {
  return request('/api/pixel_names', {
    method: 'GET',
    params,
  });
}
export async function fetchAgentNames(params) {
  return request('/api/pixel_source_agent_names', {
    method: 'GET',
    params,
  });
}

export async function fetchBmIds(params) {
  return request('/api/pixels_source_bm_ids', {
    method: 'GET',
    params,
  });
}
export async function updateBanDate(data) {
  const { id } = data;
  return request(`/api/error_msg/${id}/ban_date`, {
    method: 'PUT',
    data,
  });
}

export async function exportRoiAllStaff(params) {
  return request('/api/stores/day_total/export', {
    method: 'GET',
    params,
  });
}

export async function exportProfitAllStaff(params) {
  return request('/api/statistical/export', {
    method: 'GET',
    params,
  });
}

export async function fetchRoiTrend(params) {
  return request('/api/stores/day_total/roi_trend', {
    method: 'GET',
    params,
  });
}

export async function fetchEmailCode(data) {
  return request('/api/asset/email/code', {
    method: 'POST',
    data,
  });
}

export async function batchDelivery(data) {
  return request('/api/batch/clip_tasks', {
    method: 'PUT',
    data,
  });
}

export async function fetchMaterialExportJob(params) {
  return request('/api/async/export_job', {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}

export async function asyncExportAd(data) {
  return request('/api/fb_ad/accounts/async/export', {
    method: 'POST',
    data,
  });
}

export async function generateDaily(data) {
  return request('/api/people/sku_dailies', {
    method: 'POST',
    data,
  });
}
export async function batchSubmitDaily(data) {
  return request('/api/batch/sku_dailies', {
    method: 'PUT',
    data,
  });
}

export async function fetchDailyDetail(params) {
  return request('/api/diff_date/sku_dailies', {
    method: 'GET',
    params,
  });
}

export async function fetchRank(params) {
  return request('/api/performance_rank', { method: 'GET', params });
}

export async function fetchPeopleAsset(params) {
  return request(`/api/error_msg/get_people_assets?people_id=${params.people_id}`, {
    method: 'GET',
  });
}

export async function fetchGooleAds(data) {
  return request('/api/google/ad_accounts_list', {
    method: 'POST',
    data,
  });
}

export async function fetchAccountName(params) {
  return request(`/api/google/customers_names`, {
    method: 'GET',
    params,
  });
}

export async function fetchAccountIds(params) {
  return request('/api/google/customers_ids', {
    method: 'GET',
    params,
  });
}

export async function exportGooleAds(data) {
  return request('/api/google/ad_accounts_list/export', {
    method: 'POST',
    data,
  });
}

export async function batchAddGoogleAdAccount(data) {
  return request('/api/batch/google/ad_accounts', {
    method: 'POST',
    data,
  });
}

export async function downGooleManualPdf() {
  return request('/api/google/manual_pdf', {
    method: 'GET',
  });
}

export async function downloadBrowserTool() {
  return request('/api/browser_tool', {
    method: 'GET',
  });
}

export async function batchAddAdAccount(data) {
  return request('/api/bath/asset/act_apply', {
    method: 'POST',
    data,
  });
}

export async function fetchCanApplyAdNum(params) {
  return request('/api/bm/can_apply_ad_num', {
    method: 'GET',
    params,
  });
}

// 产品来源标签列表
export async function fetchProductSourceList() {
  return request('/api/product_source', {
    method: 'GET',
  });
}

// 添加产品来源标签
export async function addProductSourceTag(data) {
  return request('/api/product_source', {
    method: 'POST',
    data,
  });
}
// 获取当前用户授权的谷歌邮箱
export async function fetchGooglePeopleEmails() {
  return request('/api/google/people/emails?fields[people_google_emails]=id,email', {
    method: 'GET',
  });
}
// 新增授权邮箱
export async function addGooglePeopleEmails(data) {
  return request('/api/google/people/emails', {
    method: 'POST',
    data,
  });
}

// 获取授权登录链接
export async function fetchGoogleAuthUrl() {
  return request('/api/google/auth_url', {
    method: 'GET',
  });
}

export async function confirmInvitation(data) {
  return request('/api/google/ad_accounts/invitation', {
    method: 'POST',
    data,
  });
}

export async function retryInvitation(data) {
  return request('/api/google/ad_accounts/retry_invitation', {
    method: 'PUT',
    data,
  });
}

// 当前用户可查看的在职人员
export async function fetchWorkPeoples() {
  return request('/api/get_normal_peoples', {
    method: 'GET',
  });
}

export async function fetchPartType() {
  return request('/api/part_type');
}

export async function modifyBalance(id) {
  return request(`/api/ad_account/balance/${id}`, {
    method: 'PUT',
  });
}

export async function verifyDomain(domain) {
  return request(`/api/verify_domain?domain=${domain}`);
}

export async function fetchRefundDetail(data) {
  return request('/api/store/order_refund_detail', {
    method: 'POST',
    data,
  });
}
export async function fetchBmTokenTemplate() {
  return request('/api/bm_token_excel');
}

export async function fetchBmToken(data) {
  return request('/api/bm_tokens', {
    method: 'POST',
    data,
  });
}

export async function exportRefundDetail(data) {
  return request('/api/store/order_refund_detail/export', {
    method: 'POST',
    data,
  });
}
export async function batchImportBmToken(data) {
  return request('/api/batch/bm_token', {
    method: 'POST',
    data,
  });
}
export async function updateBmToken(data) {
  return request('/api/bm_token', {
    method: 'PUT',
    data,
  });
}

// 毛利审核趋势图
export async function fetchProfitTrend(params) {
  return request('/api/statistical/detail', {
    method: 'GET',
    params,
  });
}
export async function fetchGoogleAdSpend(data) {
  return request('/api/google/ad/spend', {
    method: 'POST',
    data,
  });
}
// 帐号投放审核列表
export async function fetchDeliveryList(data) {
  return request('/api/delivery_review_list', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

export async function exportGoogleAdSpend(data) {
  return request('/api/download/google/ad/spend', {
    method: 'POST',
    data,
  });
}

// 帐号投放审核详情
export async function fetchDeliveryDetail(data) {
  return request('/api/delivery_review_detail', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

export async function deleteGoogleAdAccount(data) {
  return request('/api/batch/google/ad_accounts', {
    method: 'DELETE',
    data,
  });
}

export async function fetchAdAccount(data) {
  const { email } = data;
  return request(`/api/google/${email}/ad_accounts`, {
    method: 'GET',
  });
}

export async function fetchGoogleAuthorizeEmail(params) {
  const realParams = {
    ...params,
    'fields[people_google_emails]': 'id,people_id,email,created_at',
    include: 'people.part,adsSuccessCount',
    'fields[people.part]': 'id,name',
    'fields[people]': 'id,name,part_id',
  };
  return request('/api/google/people/emails', {
    method: 'GET',
    params: { ...realParams },
  });
}

export async function fetchAllocatesAds(data) {
  const { email } = data;
  return request(`/api/google/${email}/allocated_ads`, {
    method: 'GET',
  });
}

export async function fetchRemoteAdAccounts(params) {
  return request('/api/remote_google/ad_accounts', {
    method: 'GET',
    params,
  });
}

export async function fetchSelectedPP(params) {
  const { id } = params;
  return request(`/api/stores/get_store_part_payment?store_id=${id}`, {
    method: 'GET',
  });
}

export async function oneKeySwitchPP(data) {
  return request('/api/stores/cutover_paypal_payment', {
    method: 'POST',
    data,
  });
}
// 获取任务ID列表
export async function fetchStoreTasks() {
  return request(`/api/stores/get_store_task`, {});
}

export async function fetchFBAdSpendTotal(data) {
  return request('/api/fb_ad_spend_total', {
    method: 'POST',
    data,
  });
}

export async function exportFBAdSpendTotal(data) {
  return request('/api/fb_ad_spend_export', {
    method: 'POST',
    data,
  });
}

// 部门管理PP账号列表
export async function fetchPartPpList() {
  return request('/api/get_paypal_info', {
    method: 'GET',
    params: {
      'filter[type]': '2,3',
    },
  });
}
// 毛利核算导入人员是否存在 verify_people
export async function fetchExistPeople(params) {
  return request(`/api/verify_people`, {
    method: 'GET',
    params,
  });
}

// 毛利插入扣减数据
export async function insertProfitData(data) {
  return request(`/api/statistical/insert_deduct_data`, {
    method: 'POST',
    data,
  });
}

// 毛利核算导入模板
export async function fetchProfitTemplate() {
  return request(`/api/statistical/deduct_import`, {
    method: 'GET',
  });
}

// 毛利核算拆分单导入模板
export async function getSplitOrderTemplate() {
  return request(`/api/statistical/split-order/template`, {
    method: 'GET',
  });
}

// 毛利核算拆分单导入
export async function postSplitOrderFile(data) {
  return request(`/api/statistical/split-order/import`, {
    method: 'POST',
    data,
  });
}

export async function saveUserHelpTime() {
  return request(`/api/me/userSaveHelp`, {
    method: 'GET',
  });
}

// 批量回滚店铺管理任务
export async function batchUpdateShopTask(data) {
  return request(`/api/stores/rollback_paypal_payment`, {
    method: 'POST',
    data,
  });
}

// 团队ROI大数据接口
export async function fetchGroupROIBigData(params) {
  return request(`/api/big_data/roi_day_detail`, {
    method: 'GET',
    params: {
      ...params,
      is_sku_quantity: 0,
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 团队ROI汇总数据
export async function fetchGroupROITotalData(params) {
  return request(`/api/big_data/roi_day_total`, {
    method: 'GET',
    params: {
      ...params,
      is_sku_quantity: 0,
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 团队ROI店铺订单数据详情
export async function fetchGroupROIStore(params) {
  return request(`/api/big_data/roi_shop_order`, {
    method: 'GET',
    params: {
      ...params,
      is_sku_quantity: 0,
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 团队ROI广告花费数据详情
export async function fetchGroupROIAdSpend(params) {
  return request(`/api/big_data/roi_ad_spend_detail`, {
    method: 'GET',
    params: {
      ...params,
      is_sku_quantity: 0,
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 团队roi导出
export async function exportRoiExcel(params) {
  return request(`/api/big_data/roi_day_detail/export`, {
    method: 'GET',
    params: {
      ...params,
      is_sku_quantity: 0,
      is_export: 1,
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export async function exportLandingPage(data) {
  return request(`/api/landing_page_excel`, {
    method: 'POST',
    data: parse(data, { arrayFormat: 'brackets' }),
  });
}

export async function batchTransferAdAccount(data) {
  return request(`/api/batch/google_ad_people_belong`, {
    method: 'PUT',
    data,
  });
}
export async function fetchGoogleAdLog(params) {
  const { customerId } = params;
  return request(`/api/google_ad/${customerId}/logs`, {
    method: 'GET',
  });
}

export async function fetchWorkPeople(params) {
  return request('/api/messages/users/staff_options', {
    method: 'GET',
    params,
  });
}

// 订单汇总新增导出任务
export async function asyncExportOrderSummary(data) {
  const { select } = data;
  delete data.select;
  return request('/api/store_order_export', {
    method: 'POST',
    data,
    headers: {
      select,
    },
  });
}

// 订单汇总新增店铺出单及pp导出任务
export async function asyncExportStoreOrderAndPP(data) {
  return request('/api/store_order_and_pp', {
    method: 'POST',
    data,
    headers: {
      export: 1,
    },
  });
}

// 供应链异步任务列表
export async function skuSupplyDownloadList(params) {
  return request('/api/async/export_job', {
    method: 'GET',
    params,
  });
}

export async function getDownloadUrlFromStorageByFileName(filename) {
  return request('/api/storages', {
    method: 'GET',
    params: {
      object: filename,
    },
  });
}
// 运营争议
export async function exportOperationDispute(params) {
  return request('/api/store/dispute', {
    method: 'get',
    params,
    headers: { Export: 'xlsx' },
  });
}

// 信用卡绑定
export async function fetchCreditCardInfo(data) {
  return request('/api/credit_info', {
    method: 'POST',
    data,
  });
}

// 创建/更新信用卡信息
export async function updateCardInfo(data) {
  return request('/api/credit_info', {
    method: 'PUT',
    data,
  });
}
// 获取信用卡绑定的持卡人
export async function fetchInfoHolders(params) {
  return request('/api/credit/card_holders', {
    method: 'GET',
    params,
  });
}

// 供应商推品审批
export async function supplyProductReview(id, data) {
  return request(`/api/scm/recommend_products/${id}/reviews`, {
    method: 'POST',
    data,
  });
}

// 供应商推品详情
export async function supplyProductDetail(id) {
  return request(`/api/scm/recommend_products/${id}`, {
    method: 'GET',
  });
}

// 修改库存产品着陆页链接
export async function updateEditProductUrl(data) {
  // 接口暂未提供
  return request('/api/products/import/logs', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

export async function skuStockExtraUrls(data) {
  return request('/api/sku/stock/extra-urls', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
//
// 批量变更邮箱授权状态
export async function batchAssignEmailStatus(data) {
  return request('/api/asset/update_assign_status', {
    method: 'post',
    data,
  });
}
export async function fetchUserBmID(params) {
  return request('/api/user/bm_ids', {
    method: 'GET',
    params,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export async function addPage(data) {
  return request('/api/reliable/page', {
    method: 'POST',
    data,
  });
}

// 产品导入修改共享部门

export async function batchUpdatePart(data) {
  return request('/api/products/share', {
    method: 'PUT',
    data,
  });
}

export async function fetchAfterSaleSkuTotalNew(data) {
  return request('/api/after-sale-reviews/unprocessed', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
export async function editAccountRemark(id, data) {
  return request(`/api/asset/act_apply/${id}`, {
    method: 'PUT',
    data,
  });
}
export async function reviewProductAfterSale(data) {
  return request('/api/after_sale/product_check_log', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
export async function reviewProductAfterSaleNew(id, data) {
  return request(`/api/after-sale-reviews/url/${id}/log`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
export async function reviewProductAfterSaleDisputeNew(id, data) {
  return request(`/api/after-sale-reviews/dispute/${id}/log`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
export async function ppSyncConfig(data) {
  return request(`/api/stores/copy-pp-rest`, { method: 'POST', data });
}
// 库存sku情况
export async function inventorySku(data) {
  return request(`/api/sku/stock/situation`, {
    method: 'POST',
    data,
  });
}
export async function fetchCheckLog(params) {
  return request('/api/after_sale/type_check_logs', {
    method: 'GET',
    params,
  });
}
// 落地页审核 获取审核记录
export async function fetchAfterSaleReviews(module, params) {
  return request(`/api/after-sale-reviews/${module}/${params.id}/logs`, {
    method: 'GET',
  });
}
// 投诉率审核 获取审核记录
export async function fetchAfterDispute(params) {
  return request(`/api/after-sale-reviews/${params.id}/dispute/logs`, {
    method: 'GET',
  });
}

export async function fetchLandingPageOrPostStatus(data) {
  return request('/api/after_sale/last_check_type', {
    method: 'POST',
    data,
  });
}

// 库存sku情况导出excel
export async function inventorySkuExport(data) {
  return request(`/api/sku/stock/situation/export`, {
    method: 'POST',
    data,
  });
}
export async function fetchProductAfterSale(data) {
  return request('/api/after_sale/product_check_list', {
    method: 'POST',
    data,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export async function fetchProductAfterSaleNew(data) {
  return request('/api/after-sale-reviews/url/index', {
    method: 'POST',
    data,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export async function fetchProductAfterSaleURLPosts(data) {
  return request('/api/after-sale-reviews/url/posts', {
    method: 'POST',
    data,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

export async function fetchProductAfterSaleNewExport(data) {
  return request('/api/after-sale-reviews/url/index', {
    method: 'POST',
    data,
    headers: {
      export: 'export',
      // select: params.select,
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export async function fetchProductAfterSaleDisputeNew(data) {
  return request('/api/after-sale-reviews/dispute/index', {
    method: 'POST',
    data,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export async function fetchProductAfterSaleDisputeNewExport(data) {
  return request('/api/after-sale-reviews/dispute/index', {
    method: 'POST',
    data,
    headers: {
      export: 'export',
      // select: params.select,
    },
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export async function fetchProductAfterSalePutLinksDisputeNew(id) {
  return request(`/api/after-sale-reviews/dispute/${id}/put-links`, {
    method: 'GET',
  });
}

export async function fetchProductAfterSalePutLinksNew({ id }) {
  return request(`/api/after-sale-reviews/${id}/url/log`, {
    method: 'POST',
  });
}

export async function fetchProductAfterVariants(id, params) {
  return request(`/api/after-sale-reviews/${id}/variants`, {
    method: 'GET',
    params,
  });
}
export async function fetchProductAfterSalePutLinks(params) {
  return request('/api/after_sale/product_check_list/put_links', {
    method: 'GET',
    params,
  });
}
export async function fetchProductAfterDisputeksNew(id, params) {
  return request(`/api/after-sale-reviews/dispute/${id}/put-links`, {
    method: 'GET',
    params,
  });
}
export async function fetchSaleReviewsNote(module, data) {
  return request(`/api/after-sale-reviews/${module}/${data.id}/note`, {
    method: 'PUT',
    data,
  });
}

export async function fetchProductAfterSaleSupplyInfo(params) {
  return request('/api/after_sale/product_check_list/supply_info', {
    method: 'GET',
    params,
  });
}

export async function fetchSonSku({ vSku }) {
  return request(`/api/review/${vSku}/son_sku`, {
    method: 'GET',
  });
}
export async function fetchvSku(sku) {
  return request(`/api/after-sale-reviews/${sku}/supplies`, {
    method: 'GET',
  });
}
export async function fetchTsSku({ sku }) {
  return request(`/api/after-sale-reviews/${sku}/ma-bang-supplies`, {
    method: 'GET',
  });
}
export async function fetchReviewsAssigners(module, data) {
  return request(`/api/after-sale-reviews/${module}/assigners`, {
    method: 'PATCH',
    data,
  });
}
export async function fetchProductAfterSaleRectifying(data) {
  return request('/api/after_sale/rectifying', {
    method: 'POST',
    data,
  });
}
export async function fetchFailReasons({ check_type_id: id }) {
  return request(`/api/after_sale/check_type/${id}/fail_reasons`, {
    method: 'GET',
  });
}
export async function fetchFailReasonsNew(params) {
  return request(`/api/after-sale-reviews/failed-reasons`, {
    method: 'GET',
    params,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export async function addAsyncExportAfterSale(data) {
  return request(`/api/after_sale/export/list`, {
    method: 'POST',
    data,
  });
}
export async function submitForReview(data) {
  return request(`/api/after_sale/rehear`, {
    method: 'POST',
    data,
  });
}
export async function submitForReviewNew(id, data) {
  return request(`/api/after-sale-reviews/url/${id}/rehear`, {
    method: 'POST',
    data,
  });
}
export async function submitForReviewDisputeNew(id, data) {
  return request(`/api/after-sale-reviews/dispute/${id}/rehear`, {
    method: 'POST',
    data,
  });
}
// 发货时效投诉率
export async function fetchShippingRate(data) {
  return request(`/api/operational-reports/delivery-disputes/search`, {
    method: 'POST',
    data,
  });
}

// 发货时效投诉率导出
export async function fetchShippingRateExcel(data) {
  return request(`/api/operational-reports/delivery-disputes/search`, {
    method: 'POST',
    data,
    headers: {
      export: 'xlsx',
    },
  });
}
// 落地页审核批量审核
export async function landingBatchReview(data) {
  return request(`/api/landing_page/batch-reviews`, {
    method: 'PUT',
    data,
  });
}

// 订单发货率
export async function fetchOrderShip(data) {
  return request(`/api/orders/expresses/rates/list`, {
    method: 'POST',
    data,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
    removeBlankProperty: true,
  });
}

// 订单发货率-导出
export async function exportOrderShip(data) {
  return request(`/api/orders/expresses/rates/export_excel`, {
    method: 'POST',
    data,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
    removeBlankProperty: true,
  });
}

export async function fetchGroupRoiTestTotal(params) {
  return request(`/api/big_data/roi_special_total`, {
    method: 'GET',
    params: {
      ...params,
      is_sku_quantity: 0,
    },
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export async function fetchSpecialParts() {
  return request(`/api/special_part`, {
    method: 'GET',
  });
}

// 获取毛利特殊部门
export async function fetchProfitSpecialParts() {
  return request(`/api/profit/special-part`, {
    method: 'GET',
  });
}

// sku核算批量重算
export async function batchRecaculate(data) {
  return request(`/api/profit/tasks`, {
    method: 'post',
    data,
    removeBlankProperty: true,
  });
}

// 编辑SKU指定属性配置
export async function updateSkuAttributeConfig(data) {
  const { id } = data;
  delete data.id;
  return request(`/api/profit/sku-attribute-config/${id}`, {
    method: 'PUT',
    data,
  });
}

// 备货申请列表
export async function fetchStockRequestList(params, headers = {}) {
  return request(`/api/stock_up_plan/stock_up_applications`, {
    method: 'GET',
    params,
    headers,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 提交备货申请
export async function submitStockRequest(data) {
  return request(`/api/stock_up_plan/stock_up_application_records`, {
    method: 'POST',
    data,
  });
}

// 备货申请审核
export async function reviewStockRequest(data) {
  return request(`/api/stock_up_plan/review_stock_up_application_records`, {
    method: 'POST',
    data,
  });
}

// 审核历史变更
export async function fetchReviewHistory(params) {
  return request(`/api/stock_up_plan/review_stock_up_application_records`, {
    method: 'GET',
    params,
  });
}

// 获取虚拟子SKU关联的所有虚拟子SKU销量数据，用于按主SKU备货时使用
export async function fetchVirtualSkuData(params) {
  return request(`/api/stock_up_plan/get_related_vs_sku`, {
    method: 'GET',
    params,
  });
}
// 产品导入商品更新
export async function productUpdate(data) {
  return request(`/api/products/overwrite/logs`, {
    method: 'post',
    data,
  });
}
export async function fetchBiType() {
  return request('/api/get_bi_type', {
    method: 'GET',
  });
}

// 日志更新产品日志
export async function fetchUpdateLog(params) {
  return request('/api/products/overwrite/logs', {
    method: 'GET',
    params,
  });
}

// 修改备货申请
export async function updateStockRequest(id, data) {
  return request(`/api/stock_up_plan/stock_up_applications/${id}`, {
    method: 'PATCH',
    data,
  });
}

// 批量修改备货申请
export async function batchUpdateStockRequest(data) {
  return request(`/api/stock_up_plan/stock_up_applications`, {
    method: 'PATCH',
    data,
  });
}

// 备货申请导出
export async function stockRequestListExport(params) {
  return request(`/api/stock_up_plan/stock_up_applications`, {
    method: 'GET',
    params,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
    responseType: 'blob',
    headers: {
      export: 'xlsx',
      model: 'App\\Models\\StockUpPlan\\StockUpApplication',
    },
  });
}
// 商品更新日志详情查询
export async function fetchUpdateLogDetail(id) {
  return request(`/api/products/overwrite/logs/${id}`, {
    method: 'GET',
  });
}
export async function fetchStopRunningReason({ check_type_id: id }) {
  return request(`/api/after_sale/check_type/${id}/stop_reasons`, {
    method: 'GET',
  });
}

// 获取bm邮箱授权信息
export async function fetchBmEmailInfo(id) {
  return request(`/api/asset/bm/get_auth_info/${id}`, {
    method: 'GET',
  });
}
// 获取三方授权配置
export async function fetchConfig(id, data) {
  return request(`/api/paypal/${id}/pp-rest-tr-config`, {
    method: 'PUT',
    data,
  });
}

// 备货申请记录详情
export async function fetchStockRecords(id) {
  return request(`/api/stock_up_plan/stock_up_application_records/${id}`, {
    method: 'GET',
  });
}
// 店铺争议特殊汇总
export async function fetchSpecialTotal(data) {
  return request(`/api/store/dispute/special_total`, {
    method: 'POST',
    data,
  });
}
// 分配全套资产-批量获取广告账号信息
export async function fetchAccountsDetail(data) {
  return request('/api/accounts/details', {
    method: 'POST',
    data,
  });
}
// 团队争议特殊汇总
export async function fetchOperationSpecialTotal(params) {
  return request(`/api/people/dispute/special_total`, {
    method: 'GET',
    params,
  });
}

// 店铺管理绑定PPCR收款账号
export async function batchBindPPCR(data) {
  return request('/api/stores/bind_ppc_rest', {
    method: 'POST',
    data,
  });
}
export async function allocateBmFullResource(data) {
  return request('/api/bm_full_resource', {
    method: 'POST',
    data,
  });
}
export async function fetchBms(params) {
  return request('/api/bms', {
    method: 'GET',
    params,
  });
}
// 店铺管理更新PPCR收款账号
export async function batchUpdatePPCR(data) {
  return request('/api/stores/update_ppc_rest', {
    method: 'PUT',
    data,
  });
}

// 店铺管理关闭PPCR收款账号
export async function batchClosePPCR(data) {
  return request('/api/stores/close_ppc_rest', {
    method: 'DELETE',
    data,
  });
}
export async function exportAfterSaleCheckRecord(data) {
  return request('/api/after_sale/export/review', {
    method: 'POST',
    data,
  });
}

// 产品售后审核记录同步导出
export async function exportAfterSaleCheckRecordNew(module, data) {
  return request(`/api/after-sale-reviews/${module}/logs`, {
    method: 'POST',
    data,
  });
}

// 账号资源组-校验BM是否存在且未分配
export async function fetchBmsDetail(data) {
  return request('/api/bms/detail', {
    method: 'POST',
    data,
  });
}
// 账号资源组-校验Page是否存在在途工单
export async function fetchPageIdDetail(data) {
  return request('/api/page/is_applying', {
    method: 'POST',
    data,
  });
}

// 备货申请-刷新素材审核结果
export async function refreshPostReview(data) {
  return request('/api/stock_up_plan/stock_up_applications_refresh_post_review_result', {
    method: 'PUT',
    data,
  });
}

//备货申请详情
export async function fetchStockRequestDetail(id) {
  return request(`/api/stock_up_plan/stock_up_applications/${id}`, {
    method: 'GET',
  });
}
//马帮目录
export async function fetchMbProductType(params) {
  return request('/api/sku/mb_product_type_list', {
    method: 'GET',
    params,
  });
}

// erp目录
export async function fetchErpProductType(params) {
  return request('/api/sku_sales/sku-category', {
    method: 'GET',
    params,
  });
}

// 申报属性
export async function fetchDeclareAttrs(params) {
  return request('/api/sku_sales/sku-declare-attribute', {
    method: 'GET',
    params,
  });
}

//产品导入商品评论详情
export async function fetchProductComment(id) {
  return request(`/api/products/${id}/reviews`, {
    method: 'GET',
  });
}
export async function fetchPageDetail(params) {
  return request(`/api/page/${params}/detail`, {
    method: 'GET',
  });
}
export async function exportPixels(data) {
  return request('/api/pixels/export', {
    method: 'POST',
    data,
  });
}
export async function batchUpdatePixel(data) {
  return request('/api/batch/pixels', {
    method: 'PUT',
    data,
  });
}

export async function fetchDomainStatu(params) {
  return request('/api/domain-pp-logs', {
    method: 'GET',
    params,
  });
}

export async function fetchVPNs() {
  return request('/api/vpns');
}

// 商品管理日志 -> 热销品日志
export function skuHotProductsLog(params) {
  return request('/api/products/crawl-logs', {
    params,
  });
}

export function fetchCountryList() {
  return request('/api/fulfillment/channels/countries');
}

export function fetchLogisticChannels(params) {
  return request('/api/fulfillment/channels/configs', {
    params,
    removeBlankProperty: true,
  });
}
export function fetchLogisticsCompany(params) {
  return request('/api/fulfillment/channels/companies', {
    method: 'GET',
    params,
  });
}
export function fetchChannel(params) {
  return request('/api/fulfillment/channels/channels', {
    method: 'GET',
    params,
  });
}
export function fetchCategory() {
  return request('/api/fulfillment/channels/categories');
}
export function updateLogisticChannels(data) {
  return request(`/api/fulfillment/channels/configs`, {
    method: 'PUT',
    data,
  });
}

export function importLogisticChannels(data) {
  return request('/api/fulfillment/channels/configs-import', {
    method: 'POST',
    data,
  });
}
export function exportLogisticChannels(params) {
  return request('/api/fulfillment/channels/configs', {
    params,
    headers: {
      export: 1,
      select: params.select,
    },
    removeBlankProperty: true,
    // responseType: 'blob',
  });
}

export function fetchLogisticChannelStatistics(params) {
  return request('/api/fulfillment/channels/statistics', {
    params,
    removeBlankProperty: true,
  });
}
export function exportLogisticChannelStatistics(params) {
  const headerExport = params.headerExport;
  delete params.headerExport;
  return request('/api/fulfillment/channels/statistics', {
    params,
    headers: {
      export: headerExport,
    },
    removeBlankProperty: true,
    // responseType: 'blob',
  });
}
export function deleteLogisticChannels(data) {
  return request(`/api/fulfillment/channels/configs`, {
    method: 'DELETE',
    data,
  });
}
export function fetchLogisticChannelCountries(params) {
  return request('/api/fulfillment/channels/prices', {
    params,
    removeBlankProperty: true,
  });
}
export function exportLogisticChannelCountries(params) {
  return request('/api/fulfillment/channels/prices', {
    params,
    headers: {
      export: 1,
    },
    removeBlankProperty: true,
  });
}
export function fetchLogisticChannelReceipts(params) {
  return request('/api/fulfillment/channels/rates', {
    params,
    removeBlankProperty: true,
  });
}
export function fetchLogisticChannelSignDateChannels(params) {
  return request('/api/fulfillment/channels/rates/channels', {
    params,
  });
}
export function fetchLogisticChannelSignDateCompanies(params) {
  return request('/api/fulfillment/channels/rates/companies', {
    params,
  });
}
export function exportLogisticChannelReceipts(params) {
  return request('/api/fulfillment/channels/rates', {
    params,
    headers: {
      export: 1,
      select: params.select,
    },
    removeBlankProperty: true,
    // responseType: 'blob',
  });
}
export function deleteLogisticChannelReceipts(data) {
  return request(`/api/fulfillment/channels/rates`, {
    method: 'DELETE',
    data,
  });
}
export function importLogisticChannelReceipts(data) {
  return request('/api/fulfillment/channels/rates-import', {
    method: 'POST',
    data,
  });
}
export function updateLogisticChannelReceipts(data) {
  const { id } = data;
  delete data.id;
  return request(`/api/fulfillment/channels/rates/${id}`, {
    method: 'PUT',
    data,
  });
}

export function fetchLogisticSkuCosts(params) {
  return request('/api/fulfillment/channels/sku-books', {
    params,
    removeBlankProperty: true,
  });
}

export function exportLogisticSkuCosts(params) {
  return request('/api/fulfillment/channels/sku-books', {
    params,
    headers: {
      export: 1,
      select: params.select,
    },
    removeBlankProperty: true,
    // responseType: 'blob',
  });
}

export function deleteLogisticSkuCosts(id) {
  return request(`/api/fulfillment/channels/sku-books/${id}`, {
    method: 'DELETE',
  });
}

export function createLogisticSkuCosts(data) {
  return request(`/api/fulfillment/channels/sku-books`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

export function updateLogisticSkuCosts(data) {
  const { id } = data;
  delete data.id;
  return request(`/api/fulfillment/channels/sku-books/${id}`, {
    method: 'PUT',
    data,
  });
}

export function importLogisticSkuCosts(data) {
  return request('/api/fulfillment/channels/import-sku-books', {
    method: 'POST',
    data,
  });
}
// 获取SKU销量趋势
export function fetchSkuQuantityTrend(data) {
  return request('/api/sku_sales/sku-quantity-trend', {
    data,
    method: 'POST',
    removeBlankProperty: true,
  });
}

// 获取SKU销量分部门趋势-小时
export function fetchSkuQuantityTrendByDepartmentEachHour(data) {
  return request('/api/sku_sales/hour-order-quantity', {
    data: parse(data, { arrayFormat: 'brackets' }),
    method: 'POST',
    removeBlankProperty: true,
    // paramsSerializer: data => parse(data, { arrayFormat: 'brackets' }),
  });
}

// 获取SKU销量分部门趋势-天
export function fetchSkuQuantityTrendByDepartmentEachDay(data) {
  return request('/api/sku_sales/day-order-quantity', {
    data: parse(data, { arrayFormat: 'brackets' }),
    method: 'POST',
    removeBlankProperty: true,
    // paramsSerializer: data => parse(data, { arrayFormat: 'brackets' }),
  });
}

// 导出SKU销量分部门趋势-天
export function exportSkuQuantityTrendByDepartmentEachDay(data) {
  return request('/api/sku_sales/dayhour-export', {
    data: parse(data, { arrayFormat: 'brackets' }),
    method: 'POST',
    removeBlankProperty: true,
    // paramsSerializer: data => parse(data, { arrayFormat: 'brackets' }),
  });
}

//新增开户记录
export function createadAccountRegisterInfos(data) {
  return request('/api/ad-account-register-infos', {
    method: 'POST',
    data,
  });
}

// 开户记录列表
export function fetchAccountRegisterInfos(params) {
  return request('/api/ad-account-register-infos', {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
//更新开户记录
export function updateAccountRegisterInfos(id, data) {
  return request(`/api/ad-account-register-infos/${id}`, {
    method: 'PUT',
    data,
  });
}

// 开户登记创建提交记录
export function createSubmitRecord(id, data) {
  return request(`/api/ad-account-register-infos/${id}/submit-info`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// 开户登记下拉列表
export function fetchAccountRegisterInfosOptions(params) {
  return request('/api/ad-account-register-infos/lists', {
    method: 'GET',
    params,
  });
}

//删除开户记录
export function deleteAccountRegisterInfos(data) {
  return request('/api/ad-account-register-infos', {
    method: 'DELETE',
    data,
  });
}

// 导出开户记录
export function exportAccountRegisterInfos(select, params) {
  return request('/api/ad-account-register-infos', {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
    headers: {
      export: 'xlsx',
      select,
    },
  });
}

// 获取主体信息和域名对应
export function fetchDomainRelationShip() {
  return request('/api/ad-account-domain-and-brand-name-relationship', {
    method: 'GET',
    removeBlankProperty: true,
  });
}

// 导入广告账号开户
export function importAdAccount(data) {
  return request('/api/import-ad-account-register-infos', {
    method: 'POST',
    data,
  });
}

// 广告账号开户导入模板
export function fetchAdAccountTemplate() {
  return request('/api/import-template-ad-account-register-infos');
}

export function fetchWorkOrderStatement(select, params) {
  return request('/api/error_msg/statistics', {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
    headers: {
      export: 'xlsx',
      select,
    },
    responseType: 'blob',
  });
}

/**
 * 获取在职的人员信息
 * @returns
 */
export function fetchUserPeoples() {
  return request('/api/users/peoples');
}

// 更新已下户
export function updateAccountRegisterInfosAccounts(id, data) {
  return request(`/api/ad-account-register-infos/${id}/accounts`, {
    method: 'PUT',
    data,
  });
}
// 物流费用统计列表
export function fetchLogisticsFreightsList(data) {
  return request('/api/logistics/logistics-freights', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// 物流费用统计
export function fetchCostStatisticsList(data) {
  const { type } = data;
  delete data.type;
  return request(`/api/logistics/logistics-freights/dashboard/${type}`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
//物流费用统计 修改
export function updateLogisticsFreights(data) {
  return request(`/api/logistics/logistics-freights`, {
    method: 'PUT',
    data,
    removeBlankProperty: true,
  });
}
//物流费用统计 删除
export function delLogisticsFreights(data) {
  return request(`/api/logistics/logistics-freights`, {
    method: 'DELETE',
    data,
  });
}
//物流费用统计 导入模板
export function fetchLogisticsFreightsImport(params) {
  return request('/api/logistics/logistics-freights/import', {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}
//物流费用统计 导入
export function fetchLogisticsFreightsExport(data) {
  return request('/api/logistics/logistics-freights/import-async', {
    method: 'POST',
    data,
  });
}
//
export function fetchLogisticsFreightsDashboardImport(data) {
  let { type } = data;
  return request(`/api/logistics/logistics-freights/dashboard/${type}/export`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
export function fetchSpendDeductDaily(id) {
  return request(`/api/spend-deduct/${id}/daily`, {
    method: 'GET',
  });
}
export function fetchSpendDeduct(params) {
  return request('/api/deduct-spend', {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}
export function fetchSpendDeductExcelTemplate() {
  return request('/api/deduct-spend/excel/template', {
    method: 'GET',
  });
}
export function importSpendDeduct(data) {
  return request('/api/deduct-spend', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
export function validateSpendDeduct(data) {
  return request('/api/deduct-spend/validated', {
    method: 'POST',
    data,
  });
}

export function withdrawHandlerMessage(id) {
  return request(`/api/error_msg/withdraw-handler-message/${id}`, {
    method: 'GET',
  });
}

// 分区配置列表
export function fetchConfigCollectionList() {
  return request(`/api/config-collection-list`, {
    method: 'GET',
  });
}

// 属性配置列表
export function fetchConfigAttributeList() {
  return request(`/api/config-attribute-list`, {
    method: 'GET',
  });
}

// 查询sku历史成本重量
export function fetchProfitSkuHistories(params) {
  return request(`/api/profit/sku-histories`, {
    method: 'GET',
    params,
  });
}

// 查询SKU属性计算过程
export function fetchSkuAttributeInfo(params) {
  return request(`/api/profit/sku-attributes`, {
    method: 'GET',
    params,
  });
}

// 获取优化配置导入模版
export function fetchConfiIimportTemplate() {
  return request(`/api/configs/collection/import-template`, {
    method: 'GET',
  });
}

//导入优化配置
export function importConfigsCollection(data) {
  return request(`/api/configs/collection/import`, {
    method: 'POST',
    data,
  });
}
// 查询国家分区
export function fetchCountryCollection() {
  return request(`/api/country-collection`, {
    method: 'GET',
  });
}
//人员审核时长明细导出
export function fetchPeopleCheckTimeExport(params) {
  return request('/api/people-check-time/export', {
    method: 'POST',
    data: params,
  });
}
//sku审核时长明细导出
export function fetchSkuCheckTimeExport(params) {
  return request('/api/sku-check-time/export', {
    method: 'POST',
    data: params,
  });
}
export function batchDeleteLogisticChannel(data) {
  return request(`/api/logistic-channel/many-delete`, {
    method: 'DELETE',
    data,
  });
}
export function batchDeleteLogisticChannelReceipts(data) {
  return request(`/api/logistic-channel/receipts-many`, {
    method: 'DELETE',
    data,
  });
}

// 转移工单
export function transferSubjectType(id, data) {
  return request(`api/error_msg/transfer/${id}`, {
    method: 'PUT',
    data,
    getResponse: true,
  });
}

// 优化配置详情
export function fetchCountryCollectionDetail(params) {
  return request(`/api/configs/freight-optimization/detail`, {
    method: 'GET',
    params,
  });
}

// 优化配置列表
export function fetchFreightOptimization(params) {
  return request(`/api/configs/freight-optimization/list`, {
    method: 'GET',
    params,
  });
}

//运费统计对比列表
export function fetchFreightComparison(params) {
  return request(`/api/profit/freight-contrast`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
// 投放审核数据 -> 审核情况分析
export function fetchAdvertiseAnalysisAuditorAnalysis(params) {
  return request(`/api/advertise-analysis/auditor-analysis`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 审核人员
export function fetchAdvertiseAnalysisAuditorAnalysisUser() {
  return request(`/api/advertise-analysis/auditor-analysis/user`, {
    method: 'GET',
  });
}

export function editSaleFaceMask(data) {
  const id = data.id;
  delete data.id;
  return request(`/api/stores/${id}/sale-face-mask`, {
    method: 'PUT',
    data,
  });
}

export function fetchDns(id) {
  return request(`/api/stores/${id}/dns`, {
    method: 'GET',
  });
}

export function fetchNewShopCountries() {
  return request('/api/sku/new-shop-countries', {
    method: 'GET',
  });
}
export function fetchCompanyAccount(params) {
  return request('/api/statistics/live-ad-rejection-rates/accounts-list', {
    method: 'GET',
    params,
  });
}
export function fetchCompany(params) {
  return request('/api/statistics/live-ad-rejection-rates/entities-list', {
    method: 'GET',
    params,
  });
}

export function addLarrProviderExportTask(params) {
  return request('/api/statistics/live-ad-rejection-rates/providers', {
    method: 'GET',
    params,
  });
}

export function addLarrEntityExportTask(params) {
  return request('/api/statistics/live-ad-rejection-rates/entities', {
    method: 'GET',
    params,
  });
}

export function addLarrAccountExportTask(params) {
  return request('/api/statistics/live-ad-rejection-rates/accounts', {
    method: 'GET',
    params,
  });
}
export function fetchLogisticChannelExportTemplate() {
  return request('/api/fulfillment/channels/configs/template-export-template', {
    method: 'GET',
  });
}
export function exportLogisticChannelExportTemplate(data) {
  return request('/api/fulfillment/channels/configs/template-export', {
    method: 'POST',
    data,
  });
}

// 报表-产品审核异常 违规情况
export function fetchNofiticationInfractionStatistics(params) {
  return request('/api/notifications/infraction-statistics', {
    params,
    removeBlankProperty: true,
  });
}

export function fetchNofiticationInfractionSku(params) {
  return request('/api/notifications/infraction-message-skus', {
    params,
  });
}

export function exportNotificationsSkuMessage(params) {
  return request('/api/notifications/sku-message-export', {
    params,
  });
}
export function fetchStatisticsAfterSaleReview(params) {
  return request('/api/statistics/after-sale-reviews', {
    params,
    removeBlankProperty: true,
  });
}
export function exportStatisticsAfterSaleReview(params, select) {
  return request('/api/statistics/after-sale-reviews', {
    params,
    removeBlankProperty: true,
    headers: {
      export: 'yes',
      // select,
    },
  });
}

export function updateOrderSkuSale(data) {
  return request('/api/profit/sku-calc/update', {
    data,
    method: 'POST',
  });
}

export function fetchIgnorePartList() {
  return request('/api/sale_calculate/ignore-part-list', {});
}

export function fetchComplaintRateReview(params) {
  return request('/api/statistics/after-sale-reviews/dispute', {
    params,
    removeBlankProperty: true,
  });
}

export function exportComplaintRateReview(params) {
  return request('/api/statistics/after-sale-reviews/dispute', {
    params,
    removeBlankProperty: true,
    headers: {
      export: 'yes',
    },
  });
}

export function fetchSKUFreight(params) {
  return request('/api/configs/sku-freight', {
    params,
    removeBlankProperty: true,
  });
}
export function exportSKUFreight(params) {
  return request('/api/configs/sku-freight', {
    params,
    removeBlankProperty: true,
    headers: {
      export: 'yes',
    },
  });
}
export function deleteSKUFreight(ids) {
  return request(`/api/configs/sku-freight/${ids}`, {
    method: 'DELETE',
  });
}
export function fetchSKUFreightTemplate() {
  return request(`/api/configs/sku-freight/template`);
}
export function importSKUFreight(data) {
  return request(`/api/configs/sku-freight/import`, {
    method: 'POST',
    data,
  });
}
// 物流跟踪 批量查询总数
export function fetchFulfillmentTracksCounter(data) {
  return request('/api/fulfillment/tracks/counter', {
    data,
    method: 'POST',
  });
}
export function fetchStatisticsRisk(params) {
  return request('/api/statistics/risk', {
    params,
    removeBlankProperty: true,
  });
}
export function exportStatisticsRisk(params) {
  return request('/api/statistics/risk', {
    params,
    removeBlankProperty: true,
    headers: {
      export: 'yes',
    },
  });
}
// 物流跟踪 所有查询总数
export function fetchFulfillmentTracksNewCounter(data) {
  return request('/api/fulfillment/tracks/new-counter', {
    data,
    method: 'POST',
  });
}
export function fetchNiceClass(data) {
  return request('/api/infringe-case/get-nice-class', {
    method: 'GET',
    data,
  });
}

// 校验侵权对象是否重复
export function checkReObjectName(params) {
  return request('/api/infringe-case/re-object-name', {
    method: 'GET',
    params,
  });
}

export function fetchInfringeCategory(data) {
  return request('/api/infringe-category', {
    method: 'GET',
    data,
  });
}
export function fetchInfringeCase(data) {
  return request('/api/infringe-case', {
    data,
    method: 'POST',
  });
}
export function fetchKeyWords(data) {
  return request('/api/infringe-case/get-keywords', {
    method: 'GET',
    data,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export function fetchCaseDetailsItem(id) {
  return request(`/api/infringe-case/${id}`, {
    method: 'GET',
  });
}
export function fetchCaseEditItem(params) {
  const { id } = params;
  return request(`/api/infringe-case/${id}`, {
    method: 'PUT',
    data: params,
    // removeBlankProperty: true,
    // paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' })
  });
}
export function fetchCaseDelItem(id) {
  return request(`/api/infringe-case/${id}`, {
    method: 'DELETE',
  });
}
//

export function fetchCaseDetailsDetail(id) {
  return request(`/api/infringe-case-detail/${id}`, {
    method: 'GET',
  });
}
export function fetchCaseLog(data) {
  return request('/api/infringe-case/logs', {
    method: 'POST',
    data,
  });
}
export function updateExpireDate(id, data) {
  return request(`/api/domains/updatesingle/${id}`, {
    method: 'PUT',
    data,
  });
}
export function adObjectStory(data) {
  return request(`/api/ad-object-story`, {
    method: 'POST',
    data,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

export function exportLogisticChannelCountriesPrices(params) {
  return request('/api/fulfillment/channels/prices', {
    params,
    headers: {
      export: 'simple',
    },
    removeBlankProperty: true,
  });
}
export function fetchStoresUpdate(data) {
  const { id } = data;
  return request(`/api/stores/${id}`, {
    method: 'PATCH',
    data,
  });
}

export function fetchStoresPPinfo(id, data) {
  return request(`/api/pp_info/${id}`, {
    method: 'PUT',
    data,
  });
}

// 广告贴监测 导入模板
export function getMonitorPostIdTemplate() {
  return request(`/api/fb/monitor-post-ids/template`);
}

// 广告贴监测 导入数据
export function importMonitorPostId(data) {
  return request(`/api/fb/monitor-post-ids`, {
    method: 'POST',
    data,
  });
}

// 广告贴监测 列表数据
export function fetchMonitorPostIdList(params) {
  return request(`/api/fb/monitor-post-ids/search`, {
    method: 'POST',
    data: params,
  });
}

// 广告贴监测 删除监测广告帖
export function deleteMonitorPostId(id) {
  return request(`/api/fb/monitor-post-ids/${id}`, {
    method: 'DELETE',
  });
}

// 广告贴监测 导出监测广告帖
export function exportMonitorPostIdList(params) {
  return request(`/api/fb/monitor-post-ids/search`, {
    method: 'POST',
    data: params,
    headers: {
      export: 1,
    },
  });
}

// 广告贴监测 下架监测广告帖
export function closeMonitorPostIds(ids) {
  return request(`/api/fb/close-post-ids`, {
    method: 'POST',
    data: {
      id: ids,
    },
  });
}
export function updateFollowPeople(id, data) {
  return request(`/api/error_msg/updateFollow/${id}`, {
    method: 'PUT',
    data,
  });
}
export function fetchPeopleList() {
  return request(`/api/people-list `, {
    method: 'GET',
  });
}

// 获取工单有权处理用户
export function fetchErrorMsgDealPeoples(params) {
  return request(`/api/error_msg/deal-users`, {
    method: 'GET',
    params,
  });
}

// 评价工单
export function evaluateErrorMsg(data) {
  const { id } = data;
  delete data.id;
  return request(`/api/error_msg/${id}/evaluate`, {
    method: 'PUT',
    data,
  });
}

// 最优渠道在线计算
export function fetchLogisticsChannelOnlineCalculation(data) {
  return request(`/api/fulfillment/channels/dimensions/search`, {
    method: 'POST',
    data,
  });
}

// 最优渠道在线计算导出
export function exportLogisticsChannelOnlineCalculation(params) {
  return request(`/api/fulfillment/channels/dimensions`, {
    method: 'GET',
    params,
    headers: {
      export: 1,
    },
  });
}

// 最优渠道在线计算导入
export function importLogisticsChannelOnlineCalculation(data) {
  return request(`/api/fulfillment/channels/dimensions`, {
    method: 'POST',
    data,
  });
}

// 最优渠道在线计算导入模板
export function importLogisticsChannelOnlineCalculationTemplate() {
  return request(`/api/fulfillment/channels/dimensions/template`);
}
// 用户管理-所属小组下拉列表
export function fetchUsersGroupNameList() {
  return request(`/api/users/group-name-list`);
}
// 用户管理-单个编辑所属小组
export function updateGroupName(id, data) {
  return request(`/api/users/${id}/update-group-name`, {
    method: 'POST',
    data,
  });
}
// PP账号争议
export function fetchPPAccountDispute(data) {
  return request(`/api/disputes/pay-pal/search`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// PP账号争议-PP账号
export function fetchPPAccountDisputeAliasNames() {
  return request(`/api/disputes/pay-pal/alias-names`, {
    method: 'GET',
  });
}

// PP账号争议-导出
export function exportPPAccountDispute(data) {
  return request(`/api/disputes/pay-pal/search`, {
    method: 'POST',
    data,
    headers: {
      export: 1,
    },
  });
}

//page授权指定个人号
export function updatePageApply(data) {
  return request(`/api/page-applies`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// 产品审核-导入违规产品处理
export function importViolatingProduct(data) {
  return request(`/api/violating-product`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// 违规产品处理-编辑单列
export function updateViolatingProduct(id, data) {
  return request(`/api/violating-product/${id}`, {
    method: 'PUT',
    data,
    // removeBlankProperty: true,
  });
}
// 违规产品处理-撤回违规产品处理
export function revokeViolatingProduct(id) {
  return request(`/api/violating-product/revoke/${id}`, {
    method: 'GET',
  });
}
// 违规产品处理-违规产品日志
export function fetchViolatingProductLog(id) {
  return request(`/api/violating-product/logs/${id}`, {
    method: 'GET',
  });
}
// 违规产品处理列表
export function fetchViolatingProduct(data) {
  return request(`/api/violating-product/index`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// 违规产品处理-编辑违规产品处理
export function editViolatingProduct(id, data) {
  return request(`/api/violating-product/infraction/${id}`, {
    method: 'PUT',
    data,
    removeBlankProperty: true,
  });
}
// 导出违规产品处理列表
export function epxortViolatingProduct(data) {
  return request(`/api/violating-product/index`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
    headers: {
      export: 1,
    },
  });
}
export function fulfillmentChannelPriceExportListHistoryTask(params) {
  return request(`/api/fulfillment/channels/prices-export-list`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}
//
export function fetchAssociationUsers() {
  return request(`/api/sku/stock/user-option`, {
    method: 'GET',
  });
}
// SKU列表-违规类型配置-列表
export function fetchViolateCategory(params) {
  return request(`/api/violate-category`, {
    method: 'GET',
    params,
  });
}
// SKU列表-违规类型配置-保存
export function updateViolateCategory(data) {
  return request(`/api/violate-category-update`, {
    method: 'PUT',
    data,
  });
}
// SKU列表-违规类型配置-日志
export function fetchViolateCategorylog() {
  return request(`/api/violate-category-logs`, {
    method: 'GET',
  });
}

// SKU列表-违规货源链接清单-列表
export function fetchViolateCategorySypply(data) {
  return request(`/api/violate-category-supply`, {
    method: 'POST',
    data,
  });
}
// SKU列表-违规货源链接清单-保存
export function updateViolateCategorySupply(data) {
  return request(`/api/violate-category-supply-update`, {
    method: 'PUT',
    data,
  });
}
// SKU列表-违规货源链接清单-日志
export function fetchViolateCategorySupplyLog(params) {
  return request(`/api/violate-category-supply-logs`, {
    method: 'GET',
    params,
  });
}
// 检验链接是否违规-给产品审核等使用
export function verifyViolateCategorySupplyExist(params) {
  return request(`/api/violate-category-supply-exist`, {
    method: 'GET',
    params,
  });
}

// 团队roi 日期禁用范围
export function fetchDataPermission(ident_id) {
  return request(`/api/data-permission/${ident_id}`, {
    method: 'GET',
  });
}

// 物流费用统计 导入任务列表
export function fetchuserImportTasks(params) {
  return request(`/api/user-import-tasks`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 物流费用统计 导入任务下载
export function fetchuserImportTasksUri(params) {
  return request(`/api/user-import-tasks/export_uri`, {
    method: 'GET',
    params,
  });
}
// 素材审核-获取默认配置部门
export function fetchDefaultConfigParts(params) {
  return request(`/api/sys-configs`, {
    method: 'GET',
    params,
  });
}
// 素材审核-批量更新申诉处理标记
export function updateAppealResult(data) {
  return request(`/api/fb_ad/postInfo/update-appeal-result`, {
    method: 'POST',
    data,
  });
}
// 违规产品处理-批量编辑
export function batchUpdateViolatingProduct(data) {
  return request(`/api/violating-product-batch-update`, {
    method: 'PUT',
    data,
  });
}

// 获取链接
export function fetchOssUrL(params) {
  return request(`/api/get-oss-url`, {
    params,
  });
}

// 违规产品处理-修改处理方式
export function updateViolatingProductFields(data) {
  return request(`/api/violating-product-update-batch-field`, {
    method: 'PUT',
    data,
  });
}

// 获取其关联人员用户选项-根据给定的user_id
export function fetchPeopleByUserId(params) {
  return request(`/api/peoples-by-user-id`, {
    method: 'GET',
    params,
  });
}
// 违规产品处理-新增导出任务
export function addViolatingProductExport(data) {
  return request(`/api/violating-product-export`, {
    method: 'POST',
    data,
  });
}
// 运费变动提醒
export function fetchSKUFreightChangeList(data) {
  return request(`/api/sku-freight-change/list`, {
    method: 'POST',
    data,
  });
}

// 落地页在投列表
export function fetchNewPeoductBoardList(params) {
  return request(`/api/new-product-board/list`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
// 落地页在投列表 导出
export function exportNewPeoductBoardList(params) {
  return request(`/api/new-product-board/list`, {
    method: 'GET',
    params,
    headers: {
      export: 'xlsx',
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
// SKU列表-是否违规选项
export function fetchViolateCategoryOptions(params) {
  return request(`/api/get-violate-category`, {
    method: 'GET',
    params,
  });
}
// 工单提交-批量或单个设置浏览标记
export function markBrowsingHistory(data) {
  return request(`/api/error_msg/set-view`, {
    method: 'POST',
    data,
  });
}

export function updateMedia(data) {
  return request(`/api/media`, {
    method: 'POST',
    data,
  });
}
// BM TOKEN管理-个人号APP列表
export function fetchUserAppTokenList(data) {
  return request('/api/bm_token/user_app_token_list', {
    method: 'POST',
    data,
  });
}
// BM TOKEN管理-更新个人号APP列表
export function updateUserAppTokenList(data) {
  return request('/api/bm_token/user_app_token_update', {
    method: 'POST',
    data,
  });
}
// BM TOKEN管理-导入个人号APP
export function importUserAppTokenList(data) {
  return request('/api/bm_token/user_app_token_batch_update', {
    method: 'POST',
    data,
  });
}
// BM TOKEN管理-个人号APP导入模版
export function fetchUserAppTokenTemplate() {
  return request('/api/bm_token/user_app_token_download_excel');
}
// 部门配置-导出
export async function exportPartConfig(params) {
  return request('/api/config', {
    method: 'GET',
    params,
    headers: {
      export: 'xlsx',
    },
  });
}
